import {FC, PropsWithChildren, useContext, useState} from 'react'
import {useForm} from 'react-hook-form'
import {useParams, useNavigate} from 'react-router-dom'

import LoadingButton from '@mui/lab/LoadingButton'
import {
  Box,
  Avatar,
  Button,
  Icon,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material'
import ContentHeader from 'app/components/Content/ContentHeader'
import MaskedPhoneNumber from 'app/components/MaskedPhoneNumber/MaskedPhoneNumber'
import Page from 'app/components/Page/Page'
import {ContactsContext} from 'app/utils/contexts/contacts-context'
import {v4 as uuidv4} from 'uuid'

import {phoneNumberLength} from '../../utils/constants/phoneNumberLength'
import {removePhoneFormat} from '../../utils/functions/formatPhoneNumber'
import {Contact, IPhoneNumber} from '../../utils/interfaces/contacts.interface'

interface PhoneNumbers {
  Mobile?: string
  Work?: string
  Home?: string
}

interface Inputs {
  name: string
  companyName?: string
  phoneNumbers: PhoneNumbers
}

const AddEditContact: FC<PropsWithChildren> = () => {
  const {contacts, editAddContact, loading} = useContext(ContactsContext)
  const navigate = useNavigate()
  const {id} = useParams()
  const [phoneNotInputed, setPhoneNotInputed] = useState(false)
  const [contact] = useState(contacts.find((item) => item.id === id))

  const [homeValid, setHomeValid] = useState(true)
  const [workValid, setWorkValid] = useState(true)
  const [mobileValid, setMobileValid] = useState(true)

  const {
    register,
    handleSubmit,
    getValues,
    formState: {errors},
  } = useForm<Inputs>()

  const getPhoneNumber = (type: string) => {
    let num
    contact?.phoneNumber.forEach(({DialString, PhoneNumberType}) => {
      if (PhoneNumberType.toLowerCase() === type) {
        num = DialString
      }
    })
    return num
  }

  const getPhoneByType = (type: string) =>
    contact?.phoneNumber.find((item) => item.PhoneNumberType === type)

  const handlePhoneArray = ({phoneNumbers}: Inputs) => {
    const {Home, Work, Mobile} = phoneNumbers

    return [Home, Work, Mobile].reduce((acc, item, i) => {
      const phoneByType = getPhoneByType(Object.keys(phoneNumbers)[i])
      return item
        ? [
            ...acc,
            {
              Id:
                phoneByType?.Id ||
                Math.floor(100000000 + Math.random() * 900000000).toString(),
              PublicId: phoneByType?.PublicId || uuidv4(),
              PhoneNumberType: Object.keys(phoneNumbers)[i],
              DialType: 0,
              DialString: removePhoneFormat(item),
            },
          ]
        : acc
    }, [] as IPhoneNumber[])
  }

  const handleAddEditContact = async (data: Inputs) => {
    const phoneObjectArray = handlePhoneArray(data)

    const newContactInformation: Contact = {
      name: data.name,
      companyName: data.companyName,
      phoneNumber: phoneObjectArray,
    }

    const callListItem = await editAddContact(
      newContactInformation,
      contact!,
      id,
    )
    if (id) {
      navigate(`/contacts/${id}`)
    } else if (callListItem && callListItem[0]) {
      const itemId = (callListItem[0] as any).CallListItem.ItemId
      navigate(`/contacts/${itemId}`)
    }
  }

  const handleCancel = () => {
    navigate(-1)
  }

  const handleValidate = () => {
    const {Work, Mobile, Home} = getValues('phoneNumbers')

    const atLeastOne = !!Work || !!Mobile || !!Home

    setHomeValid(removePhoneFormat(Home!).length === 11 || !Home?.length)
    setWorkValid(removePhoneFormat(Work!).length === 11 || !Work?.length)
    setMobileValid(removePhoneFormat(Mobile!).length === 11 || !Mobile?.length)

    const isValid = [Work, Mobile, Home].every(
      (number = '') =>
        removePhoneFormat(number).length === phoneNumberLength ||
        !number?.length,
    )

    setPhoneNotInputed(!atLeastOne)

    return atLeastOne && isValid
  }

  const handleNumberChange = () => {
    setPhoneNotInputed(false)
  }

  return (
    <Page title='AddEdit' id='addEdit'>
      <Box
        sx={{
          display: 'grid',
          gridTemplateRows: `100px`,
          overflow: 'hidden',
          height: '100%',
        }}
      >
        <ContentHeader title={!id ? 'Add Contact' : 'Edit Contact'} />
        <Box sx={{height: '100%', bgcolor: 'background.paper'}}>
          <Box
            sx={{
              display: 'flex',
              alignContent: 'center',
              justifyContent: 'center',
              padding: '36px',
            }}
          >
            <Avatar
              sx={{
                height: '96px',
                width: '96px',
                bgcolor: 'warning.dark',
                fontSize: '46px',
              }}
            >
              {contact?.name ? (
                contact?.name.substring(0, 1)
              ) : (
                <Icon sx={{fontSize: '46px'}}>person</Icon>
              )}
            </Avatar>
          </Box>
          <form
            onSubmit={handleSubmit((data) => {
              handleAddEditContact(data)
            })}
          >
            <Box sx={{display: 'grid', gap: '24px', padding: '0 24px'}}>
              <Box>
                <TextField
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...register('name', {
                    required: 'Name is required.',
                  })}
                  error={!!errors.name}
                  disabled={loading}
                  sx={{
                    bgcolor: 'secondary.main',
                    borderRadius: '35px',
                    width: '100%',
                    fontSize: '16px',

                    '& .MuiOutlinedInput-root': {
                      borderRadius: '35px',
                      padding: '0 14px',
                      fontWeight: 'bold',
                    },
                  }}
                  placeholder='Name'
                  defaultValue={contact?.name}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment
                        position='end'
                        sx={{color: 'primary.main', paddingRight: '12px'}}
                      >
                        <Icon>person</Icon>
                      </InputAdornment>
                    ),
                  }}
                />
                {!!errors.name && (
                  <Typography sx={{color: 'text.primary', paddingLeft: '24px'}}>
                    Name is required.
                  </Typography>
                )}
              </Box>
              <TextField
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...register('companyName')}
                disabled={loading}
                sx={{
                  bgcolor: 'secondary.main',
                  borderRadius: '35px',
                  width: '100%',
                  fontSize: '16px',
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '35px',
                    padding: '0 14px',
                    fontWeight: 'bold',
                  },
                }}
                placeholder='Company Name'
                defaultValue={contact?.companyName}
                InputProps={{
                  startAdornment: (
                    <InputAdornment
                      position='end'
                      sx={{color: 'primary.main', paddingRight: '12px'}}
                    >
                      <Icon>business</Icon>
                    </InputAdornment>
                  ),
                }}
              />

              <Box>
                <TextField
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...register('phoneNumbers.Home', {
                    minLength: 16,
                    validate: handleValidate,
                    onChange: handleNumberChange,
                  })}
                  error={phoneNotInputed || !homeValid}
                  disabled={loading}
                  sx={{
                    bgcolor: 'secondary.main',
                    borderRadius: '35px',
                    width: '100%',
                    fontSize: '16px',
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '35px',
                      padding: '0 14px',
                      fontWeight: 'bold',
                    },
                  }}
                  placeholder='Home Number'
                  defaultValue={getPhoneNumber('home')}
                  InputProps={{
                    inputComponent: MaskedPhoneNumber as any,
                    startAdornment: (
                      <InputAdornment
                        position='end'
                        sx={{color: 'primary.main', paddingRight: '12px'}}
                      >
                        <Icon>home</Icon>
                      </InputAdornment>
                    ),
                  }}
                />
                {!homeValid && (
                  <Typography sx={{color: 'text.primary', paddingLeft: '24px'}}>
                    Phone number requires 11 characters
                  </Typography>
                )}
              </Box>

              <Box>
                <TextField
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...register('phoneNumbers.Work', {
                    onChange: handleNumberChange,
                    validate: handleValidate,
                  })}
                  error={phoneNotInputed || !workValid}
                  disabled={loading}
                  sx={{
                    bgcolor: 'secondary.main',
                    borderRadius: '35px',
                    width: '100%',
                    fontSize: '16px',
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '35px',
                      padding: '0 14px',
                      fontWeight: 'bold',
                    },
                  }}
                  placeholder='Work Number'
                  defaultValue={getPhoneNumber('work')}
                  InputProps={{
                    inputComponent: MaskedPhoneNumber as any,
                    startAdornment: (
                      <InputAdornment
                        position='end'
                        sx={{color: 'primary.main', paddingRight: '12px'}}
                      >
                        <Icon>work</Icon>
                      </InputAdornment>
                    ),
                  }}
                />
                {!workValid && (
                  <Typography sx={{color: 'text.primary', paddingLeft: '24px'}}>
                    Phone number requires 11 characters
                  </Typography>
                )}
              </Box>

              <Box>
                <TextField
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...register('phoneNumbers.Mobile', {
                    onChange: handleNumberChange,
                    validate: handleValidate,
                  })}
                  error={phoneNotInputed || !mobileValid}
                  disabled={loading}
                  sx={{
                    bgcolor: 'secondary.main',
                    borderRadius: '35px',
                    width: '100%',
                    fontSize: '16px',
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '35px',
                      padding: '0 14px',
                      fontWeight: 'bold',
                    },
                  }}
                  placeholder='Mobile Number'
                  defaultValue={getPhoneNumber('mobile')}
                  InputProps={{
                    inputComponent: MaskedPhoneNumber as any,
                    startAdornment: (
                      <InputAdornment
                        position='end'
                        sx={{color: 'primary.main', paddingRight: '12px'}}
                      >
                        <Icon>phone_android</Icon>
                      </InputAdornment>
                    ),
                  }}
                />
                {!mobileValid && (
                  <Typography sx={{color: 'text.primary', paddingLeft: '24px'}}>
                    Phone number requires 11 characters
                  </Typography>
                )}
              </Box>
            </Box>
            <Box
              sx={{
                display: 'grid',
                alignContent: 'center',
                justifyContent: 'center',
                marginTop: '35%',
              }}
            >
              <Box
                sx={{
                  display: 'grid',
                  alignContent: 'center',
                  justifyContent: 'center',
                  gridTemplateColumns: '1fr 1fr',
                  gap: '80px',
                }}
              >
                <Button
                  sx={{
                    bgcolor: 'background.paper',
                    color: 'text.primary',
                    border: 'solid',
                    borderRadius: '35px',
                    height: '56px',
                    width: '200px',
                    fontWeight: 'bold',
                    fontSize: '15px',
                    textTransform: 'unset !important',
                  }}
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
                <LoadingButton
                  type='submit'
                  sx={{
                    bgcolor: 'primary.main',
                    color: 'text.primary',
                    borderRadius: '35px',
                    height: '56px',
                    width: '200px',
                    fontWeight: 'bold',
                    fontSize: '15px',
                    textTransform: 'unset !important',
                  }}
                  loading={loading}
                  disabled={loading}
                >
                  Save
                </LoadingButton>
              </Box>
              {phoneNotInputed && (
                <Box
                  sx={{
                    display: 'flex',
                    alignContent: 'center',
                    justifyContent: 'center',
                    padding: '14px',
                  }}
                >
                  <Typography sx={{color: 'text.primary'}}>
                    At least one phone number is required.
                  </Typography>
                </Box>
              )}
            </Box>
          </form>
        </Box>
      </Box>
    </Page>
  )
}

export default AddEditContact
