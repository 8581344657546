export const secondsToMinutes = (time: number) => {
  const num = Math.floor(time / 60)
  return `${num > 10 ? num : `0${num}`}:${
    (time % 60 && time < 10 && `0${time % 60}`) ||
    (time % 60 && time >= 10 && time % 60) ||
    (!(time % 60) && '00')
  }`
}

export const formatTime = (time: Date) => {
  const hours = time.getHours()
  const minutes = time.getMinutes()
  return `${hours}:${minutes < 10 ? `0${minutes}` : minutes}`
}
