import {
  FC,
  createContext,
  useState,
  useContext,
  useEffect,
  PropsWithChildren,
} from 'react'

import {
  SorensonAccountProvider,
  LogInCredentials,
  Core,
} from '@sorenson-eng/videophone-core-services'
import {setItem, getValue, removeItem} from 'app/utils/functions/sessionStorage'
// eslint-disable-next-line import/no-extraneous-dependencies

interface IAuthContext {
  name: string
  phoneNumber: number
  isLoggedIn: boolean | null
  setUserData: (name: string, phoneNumber: number) => void
  clearUserData: () => void
  signIn: (data: LogInCredentials) => void
  signOut: () => void
}

export const AuthContext = createContext<IAuthContext>({
  name: '',
  phoneNumber: 0,
  isLoggedIn: false,
  setUserData: (userName: string, userPhoneNumber: number) => {
    console.log(userName, userPhoneNumber)
  },
  clearUserData: () => {},
  signIn: () => {},
  signOut: () => {},
})
const SESSION_KEY = 'isLoggedIn'
const AUTH_TOKEN = 'authToken'

export const AuthContextProvider: FC<PropsWithChildren> = ({children}) => {
  const [name, setName] = useState<string>('')
  const [phoneNumber, setPhoneNumber] = useState<number>(0)
  const [isLoggedIn, setIsLoggedIn] = useState<boolean | null>(null)
  const [authToken] = useState(getValue<any>(AUTH_TOKEN))
  const core = Core.getInstance()

  const setUserData = (userName: string, userPhoneNumber: number) => {
    setName(userName)
    setPhoneNumber(userPhoneNumber)
    setIsLoggedIn(true)
  }

  const clearUserData = () => {
    setName('')
    setPhoneNumber(0)
    setIsLoggedIn(false)
  }

  const signIn = async ({username, password}: LogInCredentials) => {
    const provider = new SorensonAccountProvider()
    try {
      const accountService = await provider.logIn({username, password})
      setUserData(username, +username)
      setItem<boolean>(SESSION_KEY, true)
      setItem<any>(AUTH_TOKEN, accountService.authToken)
      console.log('Account Service', accountService)
    } catch (e) {
      console.log('There was an error while logging in', e)
      throw e
    }
  }

  const signOut = () => {
    setIsLoggedIn(false)
    removeItem(SESSION_KEY)
    removeItem(AUTH_TOKEN)
  }

  useEffect(() => {
    const token = getValue<any>(AUTH_TOKEN)

    if (token) core.authToken = authToken
    setIsLoggedIn(!!token)
  }, [])

  return (
    <AuthContext.Provider
      value={{
        name,
        phoneNumber,
        isLoggedIn,
        setUserData,
        clearUserData,
        signIn,
        signOut,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = () => useContext(AuthContext)
