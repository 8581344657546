import {forwardRef} from 'react'
import {PatternFormat, PatternFormatProps} from 'react-number-format'

interface CustomProps {
  onChange: (event: {target: {name: string; value: string}}) => void
  name: string
}

const MaskedPhoneNumber = forwardRef<PatternFormatProps, CustomProps>(
  (props, ref) => {
    const {onChange, ...other} = props
    return (
      <PatternFormat
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          })
        }}
        format='# (###) ###-####'
        valueIsNumericString
      />
    )
  },
)

export default MaskedPhoneNumber
