import {
  FC,
  useState,
  useEffect,
  useMemo,
  ChangeEvent,
  useContext,
  PropsWithChildren,
} from 'react'

import BackspaceIcon from '@mui/icons-material/Backspace'
import GroupWorkIcon from '@mui/icons-material/GroupWork'
import {Button, TextField} from '@mui/material'
import Box from '@mui/material/Box'
import Icon from '@mui/material/Icon'
import IconButton from '@mui/material/IconButton'
import ContactAutocomplete from 'app/components/ContactsAutoComplete/ContactsAutoComplete'
import DialButton from 'app/components/DialButton/DialButton'
import MaskedPhoneNumber from 'app/components/MaskedPhoneNumber/MaskedPhoneNumber'
import Page from 'app/components/Page/Page'
import {allowedCharacters} from 'app/utils/constants/allowedCharacters'
import {AuthContext} from 'app/utils/contexts/auth-context'
import {CallContext, CallState} from 'app/utils/contexts/call.context'
import {formatPhoneNumber} from 'app/utils/functions/formatPhoneNumber'

const Dial: FC<PropsWithChildren> = () => {
  const {setIdToCall, idToCall, setCurrentCallState} = useContext(CallContext)
  const [dialedPhoneNumber, setDialedPhoneNumber] = useState<string>('')

  const handleDialedNumber: Function = (dialedNumber: string) => {
    setDialedPhoneNumber(dialedPhoneNumber + dialedNumber)
  }

  const {phoneNumber} = useContext(AuthContext)

  const dialButtons = useMemo(
    () => [
      {id: '1', value: '1', subText: '', onClick: handleDialedNumber},
      {id: '2', value: '2', subText: 'A B C', onClick: handleDialedNumber},
      {id: '3', value: '3', subText: 'D E F ', onClick: handleDialedNumber},
      {id: '4', value: '4', subText: 'G H I', onClick: handleDialedNumber},
      {id: '5', value: '5', subText: 'J K L', onClick: handleDialedNumber},
      {id: '6', value: '6', subText: 'M N O', onClick: handleDialedNumber},
      {id: '7', value: '7', subText: 'P Q R S', onClick: handleDialedNumber},
      {id: '8', value: '8', subText: 'T U V', onClick: handleDialedNumber},
      {id: '9', value: '9', subText: 'W X Y Z', onClick: handleDialedNumber},
      {
        id: '*',
        value: '*',
        subText: '',
        onClick: () => console.log('Audio toggled'),
        icon: 'keyboard_voice',
      },
      {id: '0', value: '0', subText: '+', onClick: handleDialedNumber},
      {
        id: '#',
        value: '#',
        subText: '',
        onClick: () => console.log('Video toggled'),
        icon: 'videocam',
      },
    ],
    [dialedPhoneNumber],
  )

  const handleDelete = () => {
    setDialedPhoneNumber(
      dialedPhoneNumber.substring(0, dialedPhoneNumber.length - 1),
    )
  }

  const handleAllowedCharacters = () => {
    if (
      !allowedCharacters.includes(
        dialedPhoneNumber[dialedPhoneNumber.length - 1],
      )
    ) {
      handleDelete()
    }
  }

  const onChangePhoneNumber = (event: ChangeEvent<HTMLInputElement>) => {
    setDialedPhoneNumber(event.target.value)
  }

  const handleIdInput = ({target}: ChangeEvent<HTMLInputElement>) => {
    setIdToCall(target.value)
  }

  const handleStartCall = () => {
    setCurrentCallState(CallState.INITIATING_CALL)
  }

  useEffect(() => {
    handleAllowedCharacters()
  }, [dialedPhoneNumber])

  return (
    <Page title='Dial' id='dial'>
      <Box
        sx={{
          display: 'grid',
          gridTemplateRows: '276px 32px 420px 72px 50px',
          gap: 3,
          bgcolor: 'background.default',
          justifyContent: 'stretch',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexGrow: 1,
            alignContent: 'center',
          }}
        >
          <ContactAutocomplete searchValue={dialedPhoneNumber} />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexGrow: 1,
            justifyContent: 'center',
            color: 'text.primary',
            bgcolor: 'transparent',
          }}
        >
          <TextField
            value={dialedPhoneNumber}
            onChange={onChangePhoneNumber}
            sx={{
              '&.MuiTextField-root': {fontSize: '50px'},
              alignItems: 'center',
            }}
            InputProps={{
              inputComponent: MaskedPhoneNumber as any,
              style: {fontSize: '26px'},
            }}
            variant='standard'
            type='text'
            autoFocus
            autoComplete='off'
          />
        </Box>
        <Box
          sx={{
            display: 'grid',
            gap: 5,
            gridTemplateColumns: 'repeat(3, 72px)',
            justifyContent: 'center',
          }}
        >
          {dialButtons.map(({id, value, subText, onClick, icon}) => (
            <DialButton
              key={id}
              id={id}
              value={value}
              onClick={onClick!}
              subText={subText}
              icon={icon}
            />
          ))}
        </Box>
        <Box
          sx={{
            display: 'grid',
            gridTemplateAreas: `'. call delete'`,
            justifyContent: 'center',
            gridTemplateColumns: 'repeat(3, 72px)',
            alignItems: 'center',
            gap: 5,
          }}
        >
          <Button
            onClick={handleStartCall}
            sx={{
              gridArea: 'call',
              bgcolor: 'primary.main',
              width: '72px',
              height: '72px',
              borderRadius: '50%',
            }}
          >
            <Icon sx={{color: 'white', fontSize: '36px'}}>local_phone</Icon>
          </Button>
          {dialedPhoneNumber.length ? (
            <IconButton
              sx={{gridArea: 'delete', color: 'text.primary'}}
              onClick={handleDelete}
            >
              <BackspaceIcon />
            </IconButton>
          ) : null}
        </Box>
        <Box
          sx={{
            color: 'text.primary',
            fontSize: '18px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <GroupWorkIcon sx={{padding: '10px'}} />
          My number: {formatPhoneNumber(phoneNumber.toString())}
          <TextField
            id='filled-basic'
            label='ID to call'
            variant='filled'
            value={idToCall}
            onChange={handleIdInput}
          />
        </Box>
      </Box>
    </Page>
  )
}
export default Dial
