import {FC, PropsWithChildren} from 'react'
import {useNavigate} from 'react-router-dom'

import {Box, Button, Divider, Icon} from '@mui/material'
import ContentHeader from 'app/components/Content/ContentHeader'
import Page from 'app/components/Page/Page'
import {useAuth} from 'app/utils/contexts/auth-context'

const Settings: FC<PropsWithChildren> = () => {
  const {signOut} = useAuth()
  const navigate = useNavigate()

  const logOut = () => {
    navigate('/login', {replace: true})
    signOut()
  }

  const settings = [{onClick: logOut, text: 'Log Out'}]

  return (
    <Page title='Settings' id='settings'>
      <Box sx={{display: 'grid', gridTemplateRows: '124px'}}>
        <ContentHeader title='Settings' />
        <Box sx={{bgcolor: 'background.paper'}}>
          {settings.map(({onClick, text}) => (
            <div key={text}>
              <Button
                onClick={onClick}
                sx={{
                  display: 'flex',
                  justifyContent: 'left',
                  width: '100%',
                  height: '78px',
                  padding: '0 20px',
                  fontSize: '18px',
                  textTransform: 'unset !important',
                }}
              >
                <Icon sx={{paddingRight: '6px'}}>logout</Icon>
                {text}
              </Button>
              <Divider sx={{bgcolor: 'text.secondary'}} />
            </div>
          ))}
        </Box>
      </Box>
    </Page>
  )
}
export default Settings
