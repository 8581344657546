import {FC, PropsWithChildren, createContext, useState} from 'react'

import {lightTheme, darkTheme} from 'app/utils/styles/theme'

export const ThemeMode = createContext({
  toggleThemeMode: () => {},
  darkMode: false,
  theme: lightTheme,
})

export const ThemeModeProvider: FC<PropsWithChildren> = ({children}) => {
  const [darkMode, setDarkMode] = useState<boolean>(false)
  const [theme, setTheme] = useState(lightTheme)

  const toggleThemeMode = () => {
    setDarkMode(!darkMode)
    setTheme(darkMode ? lightTheme : darkTheme)
  }

  return (
    <ThemeMode.Provider value={{toggleThemeMode, darkMode, theme}}>
      {children}
    </ThemeMode.Provider>
  )
}
