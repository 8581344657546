/* eslint-disable no-param-reassign */
import {
  FC,
  createContext,
  useState,
  useEffect,
  useRef,
  useCallback,
  useContext,
  PropsWithChildren,
  MutableRefObject,
  Dispatch,
  SetStateAction,
} from 'react'

import {AuthContext} from 'app/utils/contexts/auth-context'

export enum CallState {
  IDLE,
  RECEIVING_CALL,
  INITIATING_CALL,
  IN_CALL,
}

type CallContextProps = {
  userVideo: MutableRefObject<any>
  stream: MediaStream | null
  myVideo: MutableRefObject<any>
  callUser: () => void
  idToCall: string
  isVideoOff: boolean
  isAudioMuted: boolean
  leaveCall: () => void
  name: string
  me: string
  setIdToCall: Dispatch<SetStateAction<string>>
  otherParticipanteName: string
  answerCall: () => void
  currentCallState: CallState
  setCurrentCallState: Dispatch<SetStateAction<CallState>>
  toggleAudio: () => void
  toggleVideo: () => void
  getLocalStream: () => Promise<void>
  handleMyVideo: () => void
}

export const CallContext = createContext<CallContextProps>(
  {} as any as CallContextProps,
)

export const CallProvider: FC<PropsWithChildren> = ({children}) => {
  const [stream, setStream] = useState<MediaStream | null>(null)
  const [isAudioMuted, setIsAudioMuted] = useState(false)
  const [isVideoOff, setIsVideoOff] = useState(false)
  const [idToCall, setIdToCall] = useState('')
  const myVideo = useRef<any>()
  const userVideo = useRef<any>()

  const {name} = useContext(AuthContext)

  const [currentCallState, setCurrentCallState] = useState(CallState.IDLE)

  const getLocalStream = useCallback(async () => {
    try {
      const localStream = await navigator.mediaDevices.getUserMedia({
        video: true,
        audio: true,
      })
      if (localStream) {
        setStream(() => localStream)
      }
    } catch (error) {
      console.log('error getLocalStream', error)
    }
  }, [myVideo])

  const toggleAudio = () => {
    if (stream) {
      stream.getAudioTracks().forEach((track) => {
        track.enabled = !track.enabled
      })
      setIsAudioMuted((current) => !current)
    }
  }

  const toggleVideo = () => {
    if (stream) {
      stream.getVideoTracks().forEach((track) => {
        track.enabled = !track.enabled
      })
      setIsVideoOff((current) => !current)
    }
  }

  const leaveCall = () => {}

  const callUser = () => {}

  useEffect(() => {
    if (stream && idToCall) {
      callUser()
    }
  }, [stream, idToCall])

  useEffect(() => {
    if (currentCallState === CallState.INITIATING_CALL) {
      getLocalStream()
    }
  }, [currentCallState])

  const answerCall = () => {}

  const handleMyVideo = () => {
    myVideo.current.srcObject = stream
  }

  useEffect(() => {
    if (stream && currentCallState === CallState.RECEIVING_CALL) {
      answerCall()
    }
  }, [stream])

  return (
    <CallContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        userVideo,
        stream,
        myVideo,
        callUser,
        idToCall,
        isVideoOff,
        isAudioMuted,
        leaveCall,
        name,
        me: '',
        setIdToCall,
        otherParticipanteName: '',
        answerCall,
        currentCallState,
        toggleVideo,
        toggleAudio,
        getLocalStream,
        handleMyVideo,
        setCurrentCallState,
      }}
    >
      {children}
    </CallContext.Provider>
  )
}
