import {FC, useState, ChangeEvent, PropsWithChildren} from 'react'
import {useForm} from 'react-hook-form'
import {Navigate} from 'react-router-dom'

import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import LoadingButton from '@mui/lab/LoadingButton'
import {
  Box,
  Button,
  Switch,
  Typography,
  InputAdornment,
  IconButton,
  OutlinedInput,
  TextField,
} from '@mui/material'
import MaskedPhoneNumber from 'app/components/MaskedPhoneNumber/MaskedPhoneNumber'
import {useAuth} from 'app/utils/contexts/auth-context'

import useLogin from './Login.service'

import ntouchLogo from '../../utils/assets/icons/ntouch.svg'
import ntouchr from '../../utils/assets/icons/ntouchr.png'

interface Inputs {
  username: string
  password: string
}

const LoginPage: FC<PropsWithChildren> = () => {
  const {login, from, loginError, loading} = useLogin()
  const {isLoggedIn} = useAuth()
  const [showPassword, setShowPassword] = useState(false)
  const [rememberPassword, setRememberPassword] = useState(false)
  const [phoneNumber, setPhoneNumber] = useState<string>('')

  const {
    register,
    handleSubmit,
    setValue,
    formState: {errors},
  } = useForm<Inputs>()

  const handleOnChange = ({target}: ChangeEvent<HTMLInputElement>) => {
    setPhoneNumber(target.value)
    setValue('username', target.value)
  }

  const handleOnBlur = () => {
    setValue('username', phoneNumber)
  }

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const handleRememberToggle = () => {
    setRememberPassword(!rememberPassword)
  }

  return !isLoggedIn ? (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          width: '66%',
          height: '74%',
          boxShadow: '0 3px 15px 0 rgba(0, 0, 0, 0.16)',
          borderRadius: '24px',
        }}
      >
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: '2fr 3fr',
            height: '100%',
          }}
        >
          <Box
            sx={{
              bgcolor: 'primary.main',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexGrow: 1,
              borderTopLeftRadius: '24px',
              borderBottomLeftRadius: '24px',
            }}
          >
            <Box sx={{display: 'grid', gridTemplateRows: '110px 30px'}}>
              <img alt='ntouch' src={ntouchLogo} />
              <img alt='ntouch' src={ntouchr} />
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              bgcolor: 'background.paper',
              borderTopRightRadius: '24px',
              borderBottomRightRadius: '24px',
            }}
          >
            <Box
              sx={{
                display: 'grid',
                gridTemplateRows: '90px 320px 160px',
                justifyContent: 'center',
                alignItems: 'center',
                bgcolor: 'background.paper',
                gap: 6,
              }}
            >
              <Typography
                sx={{
                  color: 'primary.main',
                  fontSize: '32px',
                  fontWeight: 'bold',
                  textAlign: 'center',
                }}
              >
                LOG IN
              </Typography>

              <form onSubmit={handleSubmit(login)}>
                <Box
                  sx={{
                    display: 'grid',
                    gridTemplateRows: '58px 58px 28px 48px 48px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: 3,
                  }}
                >
                  <div>
                    <TextField
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...register('username', {
                        required: 'Phone Number is required!',
                        onChange: handleOnChange,
                        onBlur: handleOnBlur,
                      })}
                      error={!!errors.username || loginError}
                      sx={{
                        bgcolor: 'secondary.main',
                        borderRadius: '35px',
                        width: '340px',
                        outline: 5,
                        '& .MuiOutlinedInput-root': {
                          borderRadius: '35px',
                          padding: '0 14px',
                        },
                      }}
                      placeholder='Phone Number'
                      InputProps={{
                        inputComponent: MaskedPhoneNumber as any,
                      }}
                      autoComplete='off'
                    />
                    {!!errors.username && (
                      <Typography sx={{color: 'error.main', fontSize: '14px'}}>
                        {errors?.username?.message}
                      </Typography>
                    )}
                  </div>
                  <Box
                    sx={{
                      display: 'grid',
                      gridTemplateRows: '58px 10px',
                      gap: 0.2,
                    }}
                  >
                    <OutlinedInput
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...register('password', {
                        required: 'Password is required!',
                      })}
                      sx={{
                        bgcolor: 'secondary.main',
                        borderRadius: '35px',
                        width: '100%',
                        padding: '0 36px 0 14px',
                      }}
                      error={!!errors.password || loginError}
                      type={showPassword ? 'text' : 'password'}
                      placeholder='Password'
                      endAdornment={
                        <InputAdornment position='end'>
                          <IconButton
                            aria-label='toggle password visibility'
                            onClick={handleClickShowPassword}
                            edge='end'
                          >
                            {showPassword ? (
                              <Visibility sx={{color: 'text.disabled'}} />
                            ) : (
                              <VisibilityOff sx={{color: 'text.disabled'}} />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    {(loginError || errors.password) && (
                      <Typography sx={{color: 'error.main', fontSize: '14px'}}>
                        {loginError
                          ? 'Incorrect username or password. Please try again'
                          : errors?.password?.message}
                      </Typography>
                    )}
                  </Box>
                  <Box sx={{color: 'text.primary'}}>
                    Remember Password
                    <Switch
                      onChange={handleRememberToggle}
                      sx={{
                        '& .MuiSwitch-switchBase': {
                          color: 'text.disabled',
                        },
                        '& .Mui-checked': {
                          color: 'primary.main',
                        },
                        '& .MuiSwitch-track': {
                          bgcolor: 'secondary.main',
                        },
                      }}
                    />
                  </Box>
                  <LoadingButton
                    type='submit'
                    loading={loading}
                    disabled={loading}
                    sx={{
                      bgcolor: 'primary.main',
                      color: 'text.primary',
                      borderRadius: '35px',
                      height: '48px',
                      fontWeight: 'bold',
                      fontSize: '15px',
                      textTransform: 'unset !important',
                    }}
                  >
                    Log In
                  </LoadingButton>
                  <Button
                    sx={{
                      bgcolor: 'background.paper',
                      color: 'text.primary',
                      border: 'solid',
                      borderRadius: '35px',
                      height: '48px',
                      fontWeight: 'bold',
                      fontSize: '15px',
                      textTransform: 'unset !important',
                    }}
                  >
                    Forgot Password?
                  </Button>
                </Box>
              </form>
              <Typography
                sx={{
                  color: 'primary.main',
                  textAlign: 'center',
                  fontWeight: 'bold',
                }}
              >
                Help
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  ) : (
    <Navigate to={from} replace />
  )
}

export default LoginPage
