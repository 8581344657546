export const BRAND_COLOR = '#09a7e0'
export const SECONDARY_COLOR = '#2F302F'

// Default Colors for
export const BLACK_BACKGROUND_COLOR = '#0a0a0a'
export const DEFAULT_FONT_COLOR = '#FFFFFF'
export const NOTIFICATION = '#F44336'
export const CONTACT_AVATAR_BACKGROUND = '#F7B30F'
export const AVATAR_1 = '#FF9800'
export const AVATAR_2 = '#8BC34A'
export const AVATAR_3 = '#2CD2C5'
export const PLACEHOLDER_TEXT = '#A5A5A5'
export const TOGGLE_BUTTON = '#747474'
export const INPUT_BACKGROUND = '#3B3B3B'
export const SEARCH_ICON = '#bcbbc3'
export const CALL_BACKGROUND = '#ededed'
export const ALERT = '#fe6f6e'
export const SEARCH_PLACEHOLDER = '#7b797d'
export const CONTACT_BACKGROUND = '#1B1B1B'
export const BLACK_NAVBAR_BG_COLOR = '#222222'
export const HIGHLIGHT = '#ffffff21'
export const SUCCESS = '#53c31b'
export const SUCCESS_BACKGROUND = '#f6ffec'
export const INFORMATION = '#48a7dc'
export const INFORMATION_BACKGROUND = '#e6f6ff'
export const ERROR = '#ed7773'
export const ERROR_BACKGROUND = '#fff2f0'
export const WARNING = '#efaf41'
export const WARNING_BACKGROUND = '#fefbe8'
export const NAVBAR_FONT_COLOR = '#747474'
