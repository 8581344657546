import {FC, useState, useEffect, PropsWithChildren, MouseEvent} from 'react'

import {Box, ToggleButtonGroup, ToggleButton} from '@mui/material'
import ContentHeader from 'app/components/Content/ContentHeader'
import Page from 'app/components/Page/Page'
import ScrollableList from 'app/components/ScrollableList/ScrollableList'

import useHistoryService from './hooks/History.service'

import {Item} from '../../components/ScrollableList/ScrollableList.props'

const History: FC<PropsWithChildren> = () => {
  const [searchValue, setSearchValue] = useState('')
  const [historyFilter, setHistoryFilter] = useState('')
  const [filteredHistory, setFilteredHistory] = useState<Item[]>([])

  const {data: history} = useHistoryService()

  const handleSearch = (value: string) => {
    setSearchValue(value)
  }

  const handleFilter = (_event: MouseEvent<HTMLElement>, filter: string) => {
    if (filter !== null) {
      setHistoryFilter(filter)
    }
  }

  const handleHistoryFilter = () => {
    setFilteredHistory(
      history &&
        history.filter(
          ({rejected}) =>
            historyFilter === '' || (rejected && rejected === historyFilter),
        ),
    )
  }

  useEffect(() => {
    handleHistoryFilter()
  }, [historyFilter, history])

  return (
    <Page title='History' id='history'>
      <Box
        sx={{
          display: 'grid',
          gridTemplateRows: `160px  5fr`,
          overflow: 'hidden',
        }}
      >
        <ContentHeader
          title='Call History'
          searchBar
          searchBarPlaceholder='Search Call History'
          searchCallBack={handleSearch}
        />
        <Box
          sx={{display: 'grid', gridTemplateRows: '60px', overflow: 'hidden'}}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              padding: '0 24px',
            }}
          >
            <ToggleButtonGroup
              size='small'
              value={historyFilter}
              exclusive
              onChange={handleFilter}
              sx={{
                bgcolor: 'secondary.main',
                width: '100%',
                height: '40px',
                borderRadius: '6px',
                '& .MuiToggleButtonGroup-grouped': {
                  margin: '3px',
                  border: 0,
                  '&:not(:last-of-type)': {
                    width: '100%',
                    borderRadius: '6px',
                    color: 'text.primary',
                    fontSize: '16px',
                    textTransform: 'unset !important',
                  },
                  '&:last-of-type': {
                    width: '100%',
                    borderRadius: '6px',
                    color: 'text.primary',
                    fontSize: '16px',
                    textTransform: 'unset !important',
                  },
                },
              }}
            >
              <ToggleButton
                value=''
                sx={{
                  '&.Mui-selected': {
                    bgcolor: 'primary.main',
                  },
                }}
              >
                All
              </ToggleButton>
              <ToggleButton
                value='missed'
                sx={{
                  '&.Mui-selected': {
                    bgcolor: 'primary.main',
                  },
                }}
              >
                Missed
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
          <ScrollableList
            avatar
            showTime
            items={filteredHistory}
            searchValue={searchValue}
          />
        </Box>
      </Box>
    </Page>
  )
}
export default History
