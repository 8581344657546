import {createTheme} from '@mui/material'

import * as colors from './colors'

const lightTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: colors.BRAND_COLOR,
      light: colors.CALL_BACKGROUND,
      contrastText: colors.HIGHLIGHT,
    },
    secondary: {
      main: colors.INPUT_BACKGROUND,
      light: colors.SEARCH_ICON,
      dark: colors.SEARCH_PLACEHOLDER,
    },
    background: {
      default: colors.BLACK_BACKGROUND_COLOR,
      paper: colors.BLACK_NAVBAR_BG_COLOR,
    },
    success: {
      main: colors.AVATAR_2,
      dark: colors.SUCCESS,
      light: colors.SUCCESS_BACKGROUND,
    },
    error: {
      main: colors.NOTIFICATION,
      dark: colors.ALERT,
      light: colors.ERROR_BACKGROUND,
    },
    warning: {
      main: colors.AVATAR_1,
      dark: colors.CONTACT_AVATAR_BACKGROUND,
      light: colors.WARNING_BACKGROUND,
    },
    info: {
      main: colors.AVATAR_3,
      dark: colors.CONTACT_BACKGROUND,
      light: colors.INFORMATION_BACKGROUND,
    },
    text: {
      primary: colors.DEFAULT_FONT_COLOR,
      secondary: colors.NAVBAR_FONT_COLOR,
      disabled: colors.PLACEHOLDER_TEXT,
    },
  },
  typography: {
    fontFamily: ['Axiforma', 'sans-serif'].join(','),
  },
})

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: colors.BRAND_COLOR,
    },
    background: {
      paper: colors.BLACK_BACKGROUND_COLOR,
    },
    text: {
      primary: colors.DEFAULT_FONT_COLOR,
      secondary: colors.NAVBAR_FONT_COLOR,
    },
  },
  typography: {
    fontFamily: ['Axiforma', 'sans-serif'].join(','),
  },
})

export {lightTheme, darkTheme}
