import {FC} from 'react'

import {Button, Box} from '@mui/material'
import Icon from '@mui/material/Icon'

import {Props} from './DialButton.props'

const DialButton: FC<Props> = (props) => {
  const {id, value, subText, onClick, icon} = props

  const onClickHandler = () => {
    onClick(value)
  }

  return (
    <Button
      id={id}
      onClick={onClickHandler}
      sx={{
        color: 'text.primary',
        borderRadius: '50%',
        bgcolor: icon ? 'text.secondary' : 'background.paper',
        width: '72px',
        height: '72px',
        fontSize: '22px',
        '&.MuiButton-root:hover': {
          bgcolor: 'primary.main',
        },
        padding: '9px 2px 15px 1px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'hidden',
      }}
    >
      {icon ? (
        <Icon sx={{fontSize: '36px'}}>{icon}</Icon>
      ) : (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          <Box sx={{fontSize: '30px', height: '36px', paddingBottom: '2px'}}>
            {value}
          </Box>
          <Box sx={{fontSize: '12px', height: '14px', paddingBottom: '10px'}}>
            {subText}
          </Box>
        </Box>
      )}
    </Button>
  )
}

export default DialButton
