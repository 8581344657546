import {FC, useContext} from 'react'
import {useLocation} from 'react-router-dom'

import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Drawer from '@mui/material/Drawer'
import List from '@mui/material/List'
import AppIcon from 'app/utils/assets/icons/app-icon'
import {CallContext, CallState} from 'app/utils/contexts/call.context'

import MenuItem from './components/MenuItem/MenuItem'
import {Props} from './Sidebar.props'

const drawerWidth = 100

const Sidebar: FC<Props> = ({routeGroups}) => {
  const {currentCallState} = useContext(CallContext)
  const location = useLocation()

  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        flexGrow: 0,
        boxSizing: 'border-box',
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
          borderRight: '1px solid #707070',
          bgcolor: 'background.default',
        },
      }}
      variant='permanent'
      anchor='left'
    >
      <Box
        sx={{
          display: 'grid',
          gap: 0,
          gridTemplateRows: '150px 2fr',
          gridColumn: '1',
          paddingTop: '28px',
          overflowX: 'hidden',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <AppIcon icon='logo' />
        </Box>
        {routeGroups.map(({id, routes}, index) => (
          <div key={id}>
            <List>
              {routes.map(({title, url, id: routeId, icon}) => (
                <MenuItem
                  key={routeId}
                  id={`${routeId}`}
                  title={title}
                  to={url}
                  icon={icon}
                  selected={
                    currentCallState === CallState.IDLE &&
                    location.pathname === url
                  }
                  isInCall={currentCallState}
                />
              ))}
            </List>
            {index !== routeGroups.length - 1 && <Divider />}
          </div>
        ))}
      </Box>
    </Drawer>
  )
}

export default Sidebar
