export const getValue = <T>(key: string): T | null => {
  const JSONValue = sessionStorage.getItem(key)
  return JSONValue ? (JSON.parse(JSONValue) as T) : null
}

export const setItem = <T>(key: string, value: T): void => {
  sessionStorage.setItem(key, JSON.stringify(value))
}

export const removeItem = (key: string): void => {
  sessionStorage.removeItem(key)
}
