import {FC, PropsWithChildren, useContext, useEffect} from 'react'
import {useNavigate} from 'react-router-dom'

import {Box} from '@mui/material'
import {CallContext, CallState} from 'app/utils/contexts/call.context'

import {Props} from './Page.props'

import Avatar from '../../utils/assets/icons/avatar.svg'

const Page: FC<PropsWithChildren<Props>> = ({children}) => {
  const {currentCallState} = useContext(CallContext)
  const navigate = useNavigate()

  useEffect(() => {
    if (currentCallState !== CallState.IDLE) {
      navigate('/call')
    }
  }, [currentCallState])

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: '2fr 3fr',
        height: '100%',
        width: '100%',
      }}
    >
      {children}
      <Box
        sx={{
          backgroundColor: 'primary.light',
          display: 'flex',
          flex: '1 0 auto',
        }}
      >
        <img
          style={{height: '100%', width: '100%'}}
          alt='user avatar'
          src={Avatar}
        />
      </Box>
    </Box>
  )
}

export default Page
