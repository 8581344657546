import {useState, useEffect} from 'react'

import {SorensonCallHistoryProvider} from '@sorenson-eng/videophone-call-history'
import {CallListItem} from '@sorenson-eng/videophone-core-services'
import {Item} from 'app/components/ScrollableList/ScrollableList.props'

const useHistoryService = () => {
  const [data, setData] = useState<Item[]>([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<any>(null)

  const provider = new SorensonCallHistoryProvider()

  const mapHistoryData = (
    {Name, DialString, Time}: CallListItem,
    status?: 'missed' | 'outgoing',
  ) => ({
    name: Name,
    phoneNumber: DialString?.toString() || '0000000000',
    date: new Date(Time || '2021-01-01T14:37'),
    rejected: status,
  })

  const getCallHistory = async () => {
    try {
      setLoading(true)
      const answeredCalledHistory = await provider.fetchCallHistory('Answered')
      const missedCalledHistory = await provider.fetchCallHistory('Missed')
      const answeredHistory: any = answeredCalledHistory.callHistoryItems?.map(
        (item) => mapHistoryData(item),
      )
      const missedHistory: any = missedCalledHistory.callHistoryItems?.map(
        (item) => mapHistoryData(item, 'missed'),
      )
      const newHistoryData = [...answeredHistory, ...missedHistory]
      setData(newHistoryData)
    } catch (e) {
      setError(e)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getCallHistory()
  }, [])
  return {data, loading, error}
}

export default useHistoryService
