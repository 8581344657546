import {useContext, useEffect} from 'react'
import {useNavigate} from 'react-router-dom'

import {Box} from '@mui/material'
import {CallContext, CallState} from 'app/utils/contexts/call.context'

import InCall from './components/InCall'
import IncommingCall from './components/IncomingCall'
import OutboundCall from './components/OutboundCall'
import {container} from './style'

const Call = () => {
  const navigate = useNavigate()
  const {leaveCall, otherParticipanteName, currentCallState, getLocalStream} =
    useContext(CallContext)

  const finishCall = () => {
    leaveCall()
  }

  useEffect(() => {
    if (currentCallState === CallState.IDLE) {
      navigate(-1)
    }
  }, [currentCallState])

  const handleAnswer = () => {
    getLocalStream()
  }

  return (
    <Box sx={container}>
      {currentCallState === CallState.IN_CALL && <InCall />}
      {currentCallState === CallState.RECEIVING_CALL && (
        <IncommingCall
          answerCall={handleAnswer}
          finishCall={finishCall}
          name={otherParticipanteName}
        />
      )}
      {currentCallState === CallState.INITIATING_CALL && <OutboundCall />}
    </Box>
  )
}

export default Call
