import {IconType} from 'app/utils/assets/icons/icon-mapping'

export interface RouteLink {
  id: number
  title: string
  url: string
  icon?: IconType
}
export interface RouteGroup {
  routes: RouteLink[]
  id: string
}

const ROUTES: RouteGroup[] = [
  {
    routes: [
      {id: 1, title: 'Dial', url: '/', icon: 'dial'},
      {id: 3, title: 'History', url: '/history', icon: 'history'},
      {id: 2, title: 'Contacts', url: '/contacts', icon: 'contacts'},
      {id: 4, title: 'SignMail', url: '/signmail', icon: 'signmail'},
      {id: 5, title: 'Settings', url: '/settings', icon: 'settings'},
    ],
    id: 'main-section',
  },
]

export default ROUTES
