import {FC} from 'react'

import {Box, Button, Typography, Icon, Divider} from '@mui/material'
import {formatPhoneNumber} from 'app/utils/functions/formatPhoneNumber'

interface IPhoneNumberCard {
  phoneNumberType: string
  dialString: string
}

const PhoneNumberCard: FC<IPhoneNumberCard> = ({
  phoneNumberType,
  dialString,
}) => (
  <>
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: '1.5fr 8fr 2.2fr',
        width: '100%',
        height: '90px',
        bgcolor: 'secondary.main',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: 'primary.main',
        }}
      >
        <Icon>
          {(phoneNumberType.toLowerCase() === 'mobile' && 'phone_android') ||
            (phoneNumberType.toLowerCase() === 'work' && 'work') ||
            (phoneNumberType.toLowerCase() === 'home' && 'home')}
        </Icon>
      </Box>
      <Box
        sx={{
          display: 'grid',
          alignItems: 'center',
        }}
      >
        <Typography sx={{paddingTop: '16px', fontSize: '18px'}}>
          {formatPhoneNumber(dialString)}
        </Typography>
        <Typography
          sx={{
            paddingBottom: '16px',
            fontSize: '16px',
            color: 'text.disabled',
          }}
        >
          {phoneNumberType}
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Button
          size='small'
          sx={{
            gridArea: 'call',
            bgcolor: 'primary.main',
            minWidth: '48px',
            width: '48px',
            height: '48px',
            borderRadius: '100%',
          }}
        >
          <Icon
            sx={{
              fontSize: '32px',
              color: 'background.paper',
            }}
          >
            local_phone
          </Icon>
        </Button>
      </Box>
    </Box>
    <Divider sx={{bgcolor: 'text.secondary'}} />
  </>
)

export default PhoneNumberCard
