import {FC, useState, useRef, useEffect} from 'react'
import ReactPlayer from 'react-player'

import {Box, IconButton, Icon, Typography, Slider} from '@mui/material'

import {secondsToMinutes} from '../../utils/functions/timeConverter'

interface IVideoPlayer {
  playBackTime: number
  videoUrl: string
}

const VideoPlayer: FC<IVideoPlayer> = ({playBackTime, videoUrl}) => {
  const [playing, setPlaying] = useState(false)
  const [timePlayed, setTimePlayed] = useState(0)
  const playerRef = useRef<ReactPlayer>(null)

  const handlePlayButton = () => {
    setPlaying(!playing)
  }

  const handleVideoEnd = () => {
    setPlaying(false)
  }

  const handleProgress = (changeState: any) => {
    setTimePlayed(Math.floor(changeState.playedSeconds))
  }

  const handleSliderChange = (changeState: any) => {
    playerRef?.current?.seekTo(changeState.target.value)
  }

  useEffect(() => {
    playerRef?.current?.seekTo(0)
    setTimePlayed(0)
  }, [videoUrl])

  return (
    <Box sx={{position: 'relative'}}>
      <ReactPlayer
        ref={playerRef}
        url={videoUrl}
        width='100%'
        height='100%'
        playing={playing}
        onEnded={handleVideoEnd}
        onProgress={handleProgress}
      />
      <Box
        sx={{
          position: 'absolute',
          left: 0,
          right: 0,
          bottom: 16,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          zIndex: 1,
          padding: '0 32px',
        }}
      >
        <Box
          sx={{
            display: 'grid',
            justifyContent: 'center',
            alignItems: 'center',
            gridTemplateColumns: '54px 1fr 120px',
            bgcolor: 'secondary.main',
            borderRadius: '50px',
            opacity: '0.9',
            height: '40px',
            paddingRight: '2px',
          }}
        >
          <IconButton onClick={handlePlayButton}>
            <Icon sx={{color: 'text.primary'}}>
              {playing ? 'pause' : 'play_arrow'}
            </Icon>
          </IconButton>
          <Slider
            sx={{
              '.MuiSlider-thumb': {
                color: 'text.primary',
                width: '12px',
                height: '12px',
              },
              '& .MuiSlider-rail': {
                bgcolor: 'text.primary',
                height: '6px',
              },
            }}
            max={playBackTime}
            value={timePlayed}
            onChange={handleSliderChange}
          />
          <Box sx={{textAlign: 'center'}}>
            <Typography sx={{color: 'text.primary'}}>
              {secondsToMinutes(timePlayed)} / {secondsToMinutes(playBackTime)}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default VideoPlayer
