import {FC} from 'react'

import Avatar from '@mui/material/Avatar'
import Divider from '@mui/material/Divider'
import ListItem from '@mui/material/ListItem'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import ListItemText from '@mui/material/ListItemText'
import {formatPhoneNumber} from 'app/utils/functions/formatPhoneNumber'

export interface IContactCard {
  name: string
  number: string
  color: string
}

const ContactCard: FC<IContactCard> = ({name, number, color}) => (
  <>
    <ListItem>
      <ListItemAvatar sx={{margin: '16px'}}>
        <Avatar
          sx={{
            width: '64px',
            height: '64px',
            backgroundColor: color,
          }}
        >
          {name.substring(0, 1)}
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        sx={{margin: '0'}}
        primaryTypographyProps={{sx: {color: 'text.primary'}}}
        primary={name}
        secondary={formatPhoneNumber(number)}
      />
    </ListItem>
    <Divider sx={{bgcolor: 'text.secondary'}} />
  </>
)

export default ContactCard
