import {FC, useState, useEffect, useContext} from 'react'

import {List} from '@mui/material'
import {ContactsContext} from 'app/utils/contexts/contacts-context'
import {Contact} from 'app/utils/interfaces/contacts.interface'

import ContactCard from './component/ContactCard'

import {colorArray} from '../../utils/constants/colorArray'

interface Props {
  searchValue: string
}

let colorCounter = -1

const handleColorCounter = () => {
  if (colorCounter >= 4) {
    colorCounter = 0
  } else {
    colorCounter += 1
  }
}

const ContactAutocomplete: FC<Props> = ({searchValue}) => {
  const {contacts} = useContext(ContactsContext)
  const [filteredContacts, setFilteredContacts] = useState<Contact[]>([])

  const filterContacts = () => {
    const newContacts =
      searchValue !== ''
        ? contacts.filter(({phoneNumber}) =>
            phoneNumber.find((number) =>
              number.DialString.includes(searchValue),
            ),
          )
        : []
    setFilteredContacts(newContacts)
  }

  useEffect(() => {
    filterContacts()
  }, [searchValue])
  return (
    <List
      sx={{
        overflowX: 'hidden',
        bgcolor: filteredContacts.length
          ? 'background.paper'
          : 'background.default',
        width: '100%',
        padding: '0 48px',
      }}
    >
      {filteredContacts.map(({name, phoneNumber}) => {
        handleColorCounter()
        return (
          <ContactCard
            key={phoneNumber[0].DialString}
            name={name}
            number={phoneNumber[0].DialString}
            color={colorArray[colorCounter]}
          />
        )
      })}
    </List>
  )
}

export default ContactAutocomplete
