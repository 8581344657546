import {CSSProperties} from 'react'

export const absoluteContainer = {
  position: 'absolute',
  left: '54px',
  right: '54px',
  bottom: '40px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
}

export const userVideoContainer = {
  position: 'absolute',
  top: '0',
  right: '0',
  width: '575px',
  height: '370px',
  borderRadius: '10px',
}

export const userGradient = {
  position: 'absolute',
  bottom: '0',
  left: '0',
  width: '100%',
  height: '20%',
  backgroundImage:
    'linear-gradient(180deg, rgba(84, 84, 84, 0), rgba(0, 0, 0, 0.82))',
}

export const nameStyle = {
  marginTop: '14px',
  fontSize: '22px',
}

export const userLabel = {
  position: 'absolute',
  bottom: '27px',
  left: '30px',
}

export const buttonsContainer = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: '1001',
}

export const incommingCall = {
  position: 'absolute',
  top: '30px',
  left: '700px',
}

export const caller = {
  textAlign: 'center',
  color: '#fff',
}

export const container = {
  display: 'flex',
  flex: 1,
  position: 'relative',
}

export const userVideoStyle: CSSProperties = {
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
}

export const myVideoStyle: CSSProperties = {
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  borderRadius: '10px',
}

export const videoContainer = {
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  bgcolor: 'black',
}

export const endCallButton = {
  color: 'white',
  borderRadius: 50,
  width: '82px',
  height: '82px',
  margin: '0 16px',
}

export const buttonFade = {
  position: 'absolute',
  height: '82px',
  width: '82px',
  borderRadius: '50%',
  border: 'solid',
  borderWidth: '14px',
  borderColor: 'white',
  opacity: '.3',
}

export const previewVideoGradient = {
  position: 'absolute',
  bottom: '0',
  left: '0',
  width: '100%',
  height: '100%',
  backgroundImage:
    'linear-gradient(to bottom, rgba(84, 84, 84, 0), rgba(0, 0, 0, 95))',
}
