import {FC, PropsWithChildren, useContext, useEffect, useState} from 'react'
import {useParams, useNavigate} from 'react-router-dom'

import {Box, Button, Avatar, Typography, Icon} from '@mui/material'
import Page from 'app/components/Page/Page'
import PhoneNumberCard from 'app/components/PhoneNumberCard/PhoneNumberCard'
import {useAlert} from 'app/utils/contexts/alert-context'
import {ContactsContext} from 'app/utils/contexts/contacts-context'

const ContactProfile: FC<PropsWithChildren> = () => {
  const navigate = useNavigate()
  const {id} = useParams()
  const {contacts, deleteContact} = useContext(ContactsContext)
  const {showAlert} = useAlert()
  const [contact] = useState(contacts.find((item) => item.id === id))

  const goBack = () => {
    navigate('/contacts')
  }

  const handleEditContact = () => {
    navigate('edit')
  }

  const handleDeleteContact = async () => {
    if (window.confirm('Delete contact?')) {
      if (contact) {
        const result = await deleteContact(contact)
        if (result) {
          showAlert('success', 'Contact deleted')
          navigate('/contacts')
        } else {
          showAlert('error', 'Unable to delete contact')
        }
      }
    }
  }

  useEffect(() => {
    if (!contact) navigate('*')
  }, [])

  return (
    <Page title='test' id='ContactProfile'>
      <Box
        sx={{
          display: 'grid',
          gridTemplateRows: '88px 202px min-content',
          color: 'white',
        }}
      >
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            padding: '0 16px',
          }}
        >
          <Box sx={{display: 'flex'}}>
            <Button
              onClick={goBack}
              sx={{textTransform: 'unset !important', fontSize: '18px'}}
            >
              <Icon sx={{paddingRight: '6px', fontSize: '20px'}}>
                arrow_back
              </Icon>
              Contacts
            </Button>
          </Box>
          <Box sx={{display: 'flex', justifyContent: 'right'}}>
            <Button
              sx={{textTransform: 'unset !important', fontSize: '18px'}}
              onClick={handleEditContact}
            >
              <Icon sx={{paddingRight: '6px', fontSize: '20px'}}>edit</Icon>
              Edit
            </Button>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'grid',
            alignItems: 'center',
            justifyContent: 'center',
            bgcolor: 'info.dark',
            padding: '28px 0 24px',
          }}
        >
          <Box
            sx={{
              display: 'grid',
              gridTemplateRows: '110px 1px',
              gap: 0.1,
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Avatar
              sx={{
                height: '96px',
                width: '96px',
                bgcolor: 'warning.dark',
                fontSize: '42px',
              }}
            >
              {contact?.name.substring(0, 1)}
            </Avatar>
          </Box>
          <Typography sx={{fontSize: '26px'}}>{contact?.name}</Typography>
        </Box>
        <Box>
          {contact?.phoneNumber.map(({DialString, PhoneNumberType}) => (
            <div key={DialString}>
              <PhoneNumberCard
                phoneNumberType={PhoneNumberType}
                dialString={DialString}
              />
            </div>
          ))}
        </Box>
        <Box
          sx={{
            display: 'grid',
            gridTemplateRows: '40px 40px 40px',
            justifyContent: 'left',
            padding: '30px 0 0 24px',
          }}
        >
          <Button
            sx={{
              justifyContent: 'left',
              textTransform: 'unset !important',
              fontSize: '18px',
            }}
          >
            Add To Favorites
          </Button>
          <Button
            sx={{
              justifyContent: 'left',
              color: 'error.dark',
              textTransform: 'unset !important',
              fontSize: '18px',
            }}
          >
            Block Contact
          </Button>
          <Button
            sx={{
              justifyContent: 'left',
              color: 'error.dark',
              textTransform: 'unset !important',
              fontSize: '18px',
            }}
            onClick={handleDeleteContact}
          >
            Delete Contact
          </Button>
        </Box>
      </Box>
    </Page>
  )
}

export default ContactProfile
