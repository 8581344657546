import {FC, PropsWithChildren, ChangeEvent} from 'react'

import {Box, OutlinedInput, Typography, InputAdornment} from '@mui/material'
import Icon from '@mui/material/Icon'

interface IContentHeader {
  title: string
  searchBar?: boolean
  searchBarPlaceholder?: string
  searchCallBack?: Function
}

const ContentHeader: FC<PropsWithChildren<IContentHeader>> = ({
  title,
  searchBar,
  searchBarPlaceholder,
  searchCallBack,
  children,
}) => {
  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    searchCallBack!(event.target.value)
  }

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateRows: '48px 48px',
        gap: 2,
        color: 'white',
        padding: '24px',
      }}
    >
      {searchBar && (
        <OutlinedInput
          sx={{
            height: '48px',
            bgcolor: 'secondary.main',
            color: 'secondary.dark',
            borderRadius: '35px',
            width: '100%',
            paddingLeft: '24px',
          }}
          startAdornment={
            <InputAdornment position='start'>
              <Icon sx={{color: 'secondary.light'}}>search</Icon>
            </InputAdornment>
          }
          placeholder={searchBarPlaceholder}
          onChange={handleSearch}
        />
      )}
      <Box
        sx={{
          display: 'grid',
          alignContent: 'center',
          gridTemplateColumns: '1fr 1fr',
        }}
      >
        <Typography variant='h2' sx={{fontSize: '32px', fontWeight: 'bold'}}>
          {title}
        </Typography>
        <Box sx={{display: 'flex', justifyContent: 'right'}}>{children}</Box>
      </Box>
    </Box>
  )
}

export default ContentHeader
