import {FC, useEffect, useState} from 'react'

import {
  Box,
  List,
  ListItem,
  Typography,
  Avatar,
  Divider,
  Icon,
} from '@mui/material'
import {formatTime} from 'app/utils/functions/timeConverter'

import {Item} from './ScrollableList.props'

import {colorArray} from '../../utils/constants/colorArray'
import {filterScrollableListItems} from '../../utils/functions/filterItems'
import {formatPhoneNumber} from '../../utils/functions/formatPhoneNumber'
import Thumbnail from '../Thumbnail/Thumbnail'

interface IScrollableList {
  items: Item[]
  searchValue: string
  avatar?: boolean
  showTime?: boolean
  onSelect?: Function
}

const ScrollableList: FC<IScrollableList> = ({
  items,
  searchValue,
  avatar,
  showTime,
  onSelect,
}) => {
  const [filteredItems, setFilteredItems] = useState(items)
  const [selectedItem, setSelectedItem] = useState('')

  const search = () => {
    setFilteredItems(filterScrollableListItems(items, searchValue))
  }

  let colorCounter = -1

  const handleColorCounter = () => {
    if (colorCounter >= 4) {
      colorCounter = 0
    } else {
      colorCounter += 1
    }
  }

  const handleSelect = (
    key: string,
    contactName?: string,
    url?: string,
    videoDuration?: number,
  ) => {
    setSelectedItem(key)

    if (onSelect) {
      onSelect({
        name: contactName,
        videoUrl: url,
        playBackTime: videoDuration,
      })
    }
  }

  useEffect(() => {
    search()
  }, [searchValue, items])

  return (
    <List
      sx={{
        borderRadius: '0',
        bgcolor: 'background.paper',
        overflowY: 'auto',
      }}
    >
      {filteredItems &&
        filteredItems.map(
          ({
            phoneNumber,
            name,
            date,
            thumbNailUrl,
            videoUrl,
            playBackTime,
            rejected,
          }) => {
            handleColorCounter()
            return (
              <div key={phoneNumber}>
                <ListItem
                  button
                  onClick={() =>
                    handleSelect(phoneNumber, name, videoUrl, playBackTime)
                  }
                  selected={phoneNumber === selectedItem}
                  sx={{
                    padding: '16px',
                    display: 'grid',
                    gridTemplateColumns: '100px 200px',
                    alignContent: 'center',
                    gap: 1.5,
                    '&.Mui-selected': {
                      bgcolor: 'primary.contrastText',
                    },
                    '&.Mui-selected:hover': {
                      bgcolor: 'primary.contrastText',
                    },
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignContent: 'center',
                      justifyContent: 'center',
                      fontSize: '18px',
                      color:
                        rejected === 'missed' ? 'error.dark' : 'text.primary',
                    }}
                  >
                    {avatar ? (
                      <Avatar
                        sx={{
                          width: '64px',
                          height: '64px',
                          backgroundColor: colorArray[colorCounter],
                          fontSize: '28px',
                        }}
                      >
                        {name ? name!.substring(0, 1) : '+'}
                      </Avatar>
                    ) : (
                      <Thumbnail
                        url={thumbNailUrl!}
                        playBackTime={playBackTime!}
                      />
                    )}
                  </Box>

                  <Box
                    sx={{
                      width: '200px',
                      color: 'text.primary',
                    }}
                  >
                    <Typography
                      component='div'
                      sx={{
                        fontSize: '18px',
                        color:
                          rejected === 'missed' ? 'error.dark' : 'text.primary',
                      }}
                    >
                      {name || `+ ${formatPhoneNumber(phoneNumber)}`}
                    </Typography>
                    <Typography color='text.disabled'>
                      {formatPhoneNumber(phoneNumber)}
                    </Typography>
                    <Typography
                      sx={{display: 'flex', alignItems: 'center'}}
                      color='text.disabled'
                    >
                      {rejected === 'outgoing' && (
                        <Icon
                          sx={{
                            fontSize: '16px',
                            color: 'text.primary',
                            paddingRight: '6px',
                          }}
                        >
                          phone_forwarded
                        </Icon>
                      )}
                      {date &&
                        `${date.toLocaleDateString()} ${
                          showTime ? formatTime(date) : ''
                        }`}
                    </Typography>
                  </Box>
                </ListItem>
                <Divider sx={{bgcolor: 'text.secondary'}} />
              </div>
            )
          },
        )}
    </List>
  )
}

export default ScrollableList
