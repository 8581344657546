import {FC} from 'react'

import {Box, Typography, Icon, Button} from '@mui/material'

import {endCallButton, buttonFade} from '../style'
import './call.css'

interface IncomingCall {
  answerCall: () => void
  finishCall: () => void
  name: string
}

const IncommingCall: FC<IncomingCall> = ({answerCall, finishCall, name}) => (
  <Box
    sx={{
      display: 'grid',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
      width: '100%',
      bgcolor: '#222222',
    }}
  >
    <Box sx={{display: 'gird', textAlign: 'center'}}>
      <Typography sx={{color: 'text.primary', fontSize: '18px'}}>
        Incoming...
      </Typography>
      <Typography sx={{color: 'text.primary', fontSize: '32px'}}>
        {name}
      </Typography>
      <Typography
        sx={{color: 'text.primary', fontSize: '18px', paddingTop: '6px'}}
      >
        1 (801) 937-4623
      </Typography>
    </Box>
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        gap: '280px',
      }}
    >
      <Button
        onClick={answerCall}
        sx={{
          bgcolor: 'primary.main',
          padding: '0',
          opacity: '100%',
          ...endCallButton,
        }}
      >
        <Box sx={buttonFade} className='buttonFade' />
        <Icon sx={{opacity: '100%'}} fontSize='large'>
          local_phone
        </Icon>
      </Button>
      <Box>
        <Button
          onClick={finishCall}
          sx={{bgcolor: 'error.main', ...endCallButton}}
        >
          <Box sx={buttonFade} className='buttonFade' />
          <Icon fontSize='large'>call_end</Icon>
        </Button>
      </Box>
    </Box>
  </Box>
)

export default IncommingCall
