import {FC} from 'react'
import {NavLink} from 'react-router-dom'

import Box from '@mui/material/Box'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import AppIcon from 'app/utils/assets/icons/app-icon'
import {CallState} from 'app/utils/contexts/call.context'

import {Props} from './MenuItem.props'

const MenuItem: FC<Props> = ({
  id,
  title,
  to,
  selected = false,
  icon = 'logo',
  isInCall,
}) => (
  <ListItem
    disabled={isInCall !== CallState.IDLE}
    button
    id={id}
    selected={selected}
    component={NavLink}
    to={to}
    sx={{
      color: 'text.secondary',
      '&.Mui-selected': {
        color: 'primary.main',
        bgcolor: 'background.default',
      },
      '&.MuiListItem-root': {
        padding: '8px',
        boxSizing: 'border-box',
      },
    }}
  >
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
      }}
    >
      <AppIcon icon={icon} active={selected} />
      <ListItemText primary={title} />
    </Box>
  </ListItem>
)

export default MenuItem
