import {FC, createContext, useState, useContext, PropsWithChildren} from 'react'

interface IAlert {
  type: 'error' | 'info' | 'warning' | 'success'
  message: string
  id: string
}

interface IAlertContext {
  showAlert: (
    type: 'error' | 'info' | 'warning' | 'success',
    message: string,
  ) => void
  alerts: IAlert[]
  closeAlert: (id: string) => void
}

export const AlertContext = createContext<IAlertContext>({
  showAlert: () => {},
  closeAlert: () => {},
  alerts: [],
})

export const AlertContextProvider: FC<PropsWithChildren> = ({children}) => {
  const [alerts, setAlerts] = useState<IAlert[]>([])

  const closeAlert = (alertId: string) => {
    const filteredAlerts = alerts.filter(({id}) => id !== alertId)

    setAlerts(filteredAlerts)
  }

  const showAlert = (
    alertType: 'error' | 'info' | 'warning' | 'success',
    alertMessage: string,
  ) => {
    const randomId = Math.floor(Math.random() * 1000).toString()

    setAlerts([
      ...alerts,
      {type: alertType, message: alertMessage, id: randomId},
    ])

    if (alertType === 'success') {
      setTimeout(() => closeAlert(randomId), 5000)
    }
  }

  return (
    <AlertContext.Provider value={{showAlert, alerts, closeAlert}}>
      {children}
    </AlertContext.Provider>
  )
}

export const useAlert = () => useContext(AlertContext)
