import {FC, ComponentProps} from 'react'

import IconButton from '@mui/material/IconButton'

type IconButtonProps = ComponentProps<typeof IconButton>
type CallButtonProps = IconButtonProps & {
  backgroundColor?: string
}

const CallButton: FC<CallButtonProps> = ({
  children,
  backgroundColor,
  ...rest
}) => (
  <IconButton
    style={{
      color: 'white',
      marginRight: '16px',
      marginLeft: '16px',
      borderRadius: 50,
      padding: 16,
      width: '82px',
      height: '82px',
      backgroundColor: backgroundColor || 'rgba(1, 1, 1, 0.5)',
    }}
    size='large'
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...rest}
  >
    {children}
  </IconButton>
)

export default CallButton
