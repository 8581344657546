import {Item} from 'app/components/ScrollableList/ScrollableList.props'

export const filterScrollableListItems = (item: Item[], searchValue: string) =>
  item.filter(
    ({name, phoneNumber, date}) =>
      phoneNumber.includes(searchValue) ||
      (name &&
        name.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())) ||
      (date && date.toLocaleDateString().includes(searchValue)),
  )
