import {FC, createContext, useState, useEffect, PropsWithChildren} from 'react'

import {SorensonContactsProvider} from '@sorenson-eng/videophone-contacts'
import {CallListItem} from '@sorenson-eng/videophone-core-services'
import {useAlert} from 'app/utils/contexts/alert-context'
import {useAuth} from 'app/utils/contexts/auth-context'
import {Contact} from 'app/utils/interfaces/contacts.interface'

interface IContactsContext {
  contacts: Contact[]
  loading: boolean
  editAddContact: (
    newContact: Contact,
    oldContact: Contact,
    id?: string,
  ) => Promise<CallListItem[] | null>
  deleteContact: (contact: Contact) => Promise<boolean>
}

export const ContactsContext = createContext<IContactsContext>({
  contacts: [],
  loading: false,
  editAddContact: async () => null,
  deleteContact: async () => true,
})

export const ContactsContextProvider: FC<PropsWithChildren> = ({children}) => {
  const [contacts, setContacts] = useState<Contact[]>([])
  const [loading, setLoading] = useState(false)
  const provider = new SorensonContactsProvider()
  const {isLoggedIn} = useAuth()

  const {showAlert} = useAlert()

  const getContacts = async () => {
    const {contacts: sdkContacts} = (await provider.fetchContacts(
      'Contact',
    )) as any

    const fetchedContacts = sdkContacts?.map(
      ({Name, CompanyName, ContactPhoneNumberList, ItemId}: any) => {
        const phoneNumbers = Array.isArray(
          ContactPhoneNumberList.ContactPhoneNumber,
        )
          ? ContactPhoneNumberList.ContactPhoneNumber.map(
              (ContactPhoneNumber: any) => ({
                Id: ContactPhoneNumber?.Id.toString() || '',
                PublicId: ContactPhoneNumber?.PublicId,
                PhoneNumberType: ContactPhoneNumber?.PhoneNumberType || '',
                DialType: ContactPhoneNumber?.DialType,
                DialString:
                  ContactPhoneNumber?.DialString.toString() || '0000000000',
              }),
            )
          : [
              {
                Id: `${ContactPhoneNumberList?.ContactPhoneNumber?.Id}`,
                PublicId: ContactPhoneNumberList?.ContactPhoneNumber?.PublicId,
                PhoneNumberType:
                  ContactPhoneNumberList?.ContactPhoneNumber?.PhoneNumberType ||
                  '',
                DialType: ContactPhoneNumberList?.ContactPhoneNumber?.DialType,
                DialString: `${
                  ContactPhoneNumberList?.ContactPhoneNumber?.DialString ||
                  '00000000000'
                }`,
              },
            ]

        return {
          name: Name as string,
          ...(CompanyName && {companyName: CompanyName}),
          phoneNumber: phoneNumbers,
          id: ItemId.toString(),
        }
      },
    )
    setContacts(fetchedContacts)
  }

  const addContact = async ({name, companyName, phoneNumber}: Contact) => {
    const newContact: CallListItem = {
      Name: name,
      CompanyName: companyName,
    }

    try {
      const result = await provider.addContact(
        newContact,
        phoneNumber as any,
        'Contact',
      )
      showAlert('success', 'Contact was added successfully')
      return result
    } catch (e) {
      console.log('error', e)
      showAlert('error', 'An error has occurred while adding the contact info')
      return null
    }
  }

  const editContact = async (newContact: Contact, contactId: string) => {
    const phoneNumberList = {ContactPhoneNumber: newContact.phoneNumber}

    const updatedContact: CallListItem = {
      Name: newContact.name,
      CompanyName: newContact.companyName,
      ItemId: +contactId,
      ContactPhoneNumberList: phoneNumberList as any,
    }

    try {
      const result = await provider.updateContact(updatedContact, 'Contact')
      showAlert('success', 'Contact was edited successfully')
      return result
    } catch (error) {
      showAlert('error', 'An error has occurred while editing the contact info')
      console.log('error', error)
      return null
    }
  }

  const editAddContact = async (
    newContact: Contact,
    _oldContact: Contact,
    id?: string,
  ) => {
    setLoading(true)
    let result: CallListItem[] | null
    if (id) {
      result = await editContact(newContact, id)
    } else {
      result = await addContact(newContact)
    }
    await getContacts()
    setLoading(false)
    return result
  }

  const deleteContact = async (contact: Contact) => {
    if (contact.id) {
      const contactToDelete: CallListItem = {
        ItemId: +contact.id,
        ContactPhoneNumberList: {
          ContactPhoneNumber: contact.phoneNumber,
        } as any,
      }
      const result = await provider.deleteContact(contactToDelete, 'Contact')
      await getContacts()
      return result
    }
    return false
  }

  useEffect(() => {
    if (isLoggedIn) getContacts()
    else setContacts([])
  }, [isLoggedIn])

  return (
    <ContactsContext.Provider
      value={{contacts, editAddContact, loading, deleteContact}}
    >
      {children}
    </ContactsContext.Provider>
  )
}
