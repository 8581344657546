import {FC, PropsWithChildren} from 'react'
import {Navigate, useLocation} from 'react-router-dom'

import {useAuth} from 'app/utils/contexts/auth-context'

const RequireAuth: FC<PropsWithChildren> = ({children}) => {
  const {isLoggedIn} = useAuth()
  const location = useLocation()
  return isLoggedIn ? (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>{children}</>
  ) : (
    <Navigate to='/login' state={{from: location}} replace />
  )
}

export default RequireAuth
