import {FC, useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'

import {Box, List, ListItem, Typography, Avatar, Divider} from '@mui/material'

import {colorArray} from '../../utils/constants/colorArray'
import {IPhoneNumber} from '../../utils/interfaces/contacts.interface'

interface Item {
  phoneNumber: Array<IPhoneNumber>
  name: string
  category?: Array<string>
}

interface IGroupedScrollableList {
  items: Item[]
  searchValue: string
}

const GroupedScrollableList: FC<IGroupedScrollableList> = ({
  items,
  searchValue,
}) => {
  const [filteredItems, setFilteredItems] = useState(items)
  const [orderedItems, setOrderedItems] = useState([])
  const navigate = useNavigate()

  items.sort((a, b) => (a.name < b.name ? -1 : 1))

  const search = () => {
    setFilteredItems(
      items.filter(
        ({name, phoneNumber}) =>
          phoneNumber[0].DialString.includes(searchValue) ||
          (name &&
            name.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())),
      ),
    )
  }

  let colorCounter = -1

  const handleColorCounter = () => {
    if (colorCounter >= 4) {
      colorCounter = 0
    } else {
      colorCounter += 1
    }
  }

  const handleItemSelect = (phoneNumber: string) => {
    navigate(phoneNumber)
  }

  useEffect(() => {
    search()
  }, [searchValue, items])

  useEffect(() => {
    setOrderedItems(
      Object.values(
        filteredItems.reduce((acc: any, item) => {
          const initial = item.name.substring(0, 1)
          if (acc[initial]) {
            acc[initial].record.push(item)
          } else {
            acc[initial] = {initial, record: [item]}
          }
          return acc
        }, {}),
      ),
    )
  }, [filteredItems])

  return (
    <List
      sx={{
        borderRadius: '0',
        bgcolor: 'background.paper',
        overflowY: 'auto',
      }}
    >
      {orderedItems.map(({record, initial}: any) =>
        record.map(({name, phoneNumber, id}: any, index: number) => {
          handleColorCounter()
          return (
            <div key={id}>
              <ListItem
                button
                onClick={() => {
                  handleItemSelect(id)
                }}
                sx={{
                  height: '80px',
                  display: 'grid',
                  gridTemplateColumns: '50px 100px 100px',
                  alignContent: 'center',
                }}
              >
                <Typography
                  sx={{
                    fontSize: '24px',
                    color: 'warning.dark',
                    display: 'flex',
                    alignContent: 'center',
                    justifyContent: 'center',
                  }}
                >
                  {index === 0 && initial}
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    alignContent: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Avatar
                    sx={{
                      width: '48px',
                      height: '48px',
                      backgroundColor: colorArray[colorCounter],
                    }}
                  >
                    {initial}
                  </Avatar>
                </Box>

                <Box
                  sx={{
                    width: '150px',
                    color: 'text.primary',
                  }}
                >
                  <Typography
                    variant='h6'
                    component='div'
                    sx={{fontSize: '16px'}}
                  >
                    {name || phoneNumber[0].dialString}
                  </Typography>
                </Box>
              </ListItem>
              <Divider sx={{bgcolor: 'text.secondary'}} />
            </div>
          )
        }),
      )}
    </List>
  )
}

export default GroupedScrollableList
