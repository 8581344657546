import {FC, PropsWithChildren} from 'react'
import {Link} from 'react-router-dom'

const NoRoute: FC<PropsWithChildren> = () => (
  <div>
    {' '}
    <strong>404 Error, No page found</strong> Go to{' '}
    <Link to='/'>Home Page</Link>{' '}
  </div>
)
export default NoRoute
