import {FC} from 'react'

import {useTheme} from '@mui/material/styles'

import {IconType, ICON_MAP} from './icon-mapping'

interface Props {
  icon: IconType
  active?: boolean
}

const AppIcon: FC<Props> = ({icon, active = false}) => {
  const theme = useTheme()
  const Icon = ICON_MAP[icon]
  return (
    <Icon
      fill={active ? theme.palette.primary.main : theme.palette.text.secondary}
    />
  )
}

export default AppIcon
