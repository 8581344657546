import {FC, PropsWithChildren} from 'react'
import {Outlet} from 'react-router-dom'

import {Box} from '@mui/material'
import RequireAuth from 'app/components/RequireAuth/RequireAuth'
import Sidebar from 'app/components/Sidebar/Sidebar'
import ROUTES from 'app/utils/constants/routes'

const Layout: FC<PropsWithChildren> = () => (
  <RequireAuth>
    <Box sx={{display: 'flex', width: '100%', height: '100%'}}>
      <Sidebar routeGroups={ROUTES} />
      <Box
        sx={{
          display: 'flex',
          flexGrow: 1,
          bgcolor: 'background.default',
        }}
      >
        <Outlet />
      </Box>
    </Box>
  </RequireAuth>
)

export default Layout
