import {useState} from 'react'
import {useLocation, useNavigate, Path} from 'react-router-dom'

import {LogInCredentials} from '@sorenson-eng/videophone-core-services'
import {useAuth} from 'app/utils/contexts/auth-context'
// eslint-disable-next-line import/no-extraneous-dependencies

interface IUseLogin {
  login: (data: LogInCredentials) => void
  from: string
  loginError: boolean
  loading: boolean
}

interface IState {
  from?: Path
}

const useLogin = (): IUseLogin => {
  const {signIn} = useAuth()
  const navigate = useNavigate()
  const {state} = useLocation()
  const [loginError, setLoginError] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)

  const from: string = (state as IState)?.from?.pathname || '/'

  const login = async (data: LogInCredentials) => {
    setLoading(true)
    try {
      await signIn(data)
      navigate(from, {replace: true})
      setLoading(false)
      setLoginError(false)
    } catch (e) {
      setLoginError(true)
      setLoading(false)
    }
  }
  return {login, from, loginError, loading}
}

export default useLogin
