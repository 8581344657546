import {Box, Snackbar, Icon, Typography, Button} from '@mui/material'
import {useAlert} from 'app/utils/contexts/alert-context'

import {SUCCESS, INFORMATION, ERROR, WARNING} from '../../utils/styles/colors'

const AUTO_CLOSE_TIME = 5000

const SnackbarAlert = () => {
  const {alerts, closeAlert} = useAlert()

  const alertType = {
    success: {
      icon: 'check',
      color: SUCCESS,
      backgroundColor: 'success.light',
      autoClose: AUTO_CLOSE_TIME,
    },
    info: {
      icon: 'info',
      color: INFORMATION,
      backgroundColor: 'info.light',
    },
    error: {
      icon: 'close',
      color: ERROR,
      backgroundColor: 'error.light',
    },
    warning: {
      icon: 'priority_high',
      color: WARNING,
      backgroundColor: 'warning.light',
    },
  }

  return (
    <Snackbar anchorOrigin={{vertical: 'top', horizontal: 'right'}} open>
      <Box>
        {alerts.map(({type, message, id}) => (
          <Box sx={{marginBottom: '10px'}} key={id}>
            <Box
              sx={{
                display: 'grid',
                alignContent: 'center',
                gridTemplateColumns: '1fr 5fr 1fr',
                width: '526px',
                height: '48px',
                borderRadius: '50px',
                bgcolor: alertType[type].backgroundColor,
                border: `solid 2px ${alertType[type].color}`,
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                {alertType[type].icon === 'info' ? (
                  <Icon
                    sx={{
                      color: alertType[type].color,
                      fontSize: '30px',
                    }}
                  >
                    {alertType[type].icon}
                  </Icon>
                ) : (
                  <Box
                    sx={{
                      bgcolor: alertType[type].color,
                      width: '24px',
                      height: '24px',
                      borderRadius: '50px',
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <Icon
                      sx={{
                        color: 'white',
                        fontSize: '18px',
                        alignSelf: 'center',
                      }}
                    >
                      {alertType[type].icon}
                    </Icon>
                  </Box>
                )}
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignContent: 'center',
                }}
              >
                <Typography
                  sx={{
                    color: 'background.default',
                    alignSelf: 'center',
                  }}
                >
                  {message}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Button
                  sx={{
                    height: '18px',
                    width: '12px',
                    color: 'secondary.main',
                    alignSelf: 'center',
                  }}
                  onClick={() => closeAlert(id)}
                >
                  <Icon>close</Icon>
                </Button>
              </Box>
            </Box>
          </Box>
        ))}
      </Box>
    </Snackbar>
  )
}

export default SnackbarAlert
