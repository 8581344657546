/* eslint-disable jsx-a11y/media-has-caption */
// example https://github.com/NikValdez/VideoChatTut
import {useContext, useEffect} from 'react'
import {useNavigate} from 'react-router-dom'

import {Box, Icon} from '@mui/material'
import Button from '@mui/material/Button'
import Avatar from 'app/utils/assets/icons/avatar.svg'
import {CallContext, CallState} from 'app/utils/contexts/call.context'

import CallButton from './CallButton'

import {
  absoluteContainer,
  userVideoContainer,
  userGradient,
  nameStyle,
  userLabel,
  buttonsContainer,
  container,
  videoContainer,
  endCallButton,
  userVideoStyle,
  myVideoStyle,
} from '../style'

const alt = (
  <img style={{height: '100%', width: '100%'}} alt='user avatar' src={Avatar} />
)

const InCall = () => {
  const navigate = useNavigate()
  const {
    userVideo,
    stream,
    myVideo,
    isVideoOff,
    isAudioMuted,
    leaveCall,
    toggleAudio,
    toggleVideo,
    currentCallState,
    handleMyVideo,
    otherParticipanteName,
  } = useContext(CallContext)

  useEffect(() => {
    handleMyVideo()
  }, [])

  const finishCall = () => {
    leaveCall()
  }

  useEffect(() => {
    if (currentCallState === CallState.IDLE) {
      navigate(-1)
    }
  }, [currentCallState])

  return (
    <>
      <Box sx={videoContainer}>
        {currentCallState === CallState.IN_CALL ? (
          <Box sx={container}>
            <video
              playsInline
              ref={userVideo}
              autoPlay
              style={userVideoStyle}
            />
          </Box>
        ) : (
          <Box
            sx={{
              alignSelf: 'center',
              ...container,
            }}
          >
            {alt}
          </Box>
        )}
      </Box>
      <Box sx={userGradient} />
      <Box sx={{...absoluteContainer, top: '48px'}}>
        <Box>
          <Box sx={userVideoContainer}>
            {stream && (
              <>
                <video
                  playsInline
                  muted
                  ref={myVideo}
                  autoPlay
                  style={myVideoStyle}
                />
                <Box sx={{borderRadius: '10px', ...userGradient}} />
                <Box sx={{color: 'text.primary', ...nameStyle, ...userLabel}}>
                  You
                </Box>
              </>
            )}
          </Box>
        </Box>
        <Box>
          <Box sx={buttonsContainer}>
            {stream && (
              <CallButton onClick={toggleVideo}>
                <Icon fontSize='large'>
                  {isVideoOff ? 'videocam_off' : 'videocam'}
                </Icon>
              </CallButton>
            )}
            {stream && (
              <CallButton onClick={toggleAudio}>
                <Icon fontSize='large'>{isAudioMuted ? 'mic_off' : 'mic'}</Icon>
              </CallButton>
            )}
            <Button
              onClick={finishCall}
              sx={{bgcolor: 'error.main', ...endCallButton}}
            >
              <Icon fontSize='large'>call_end</Icon>
            </Button>
          </Box>
          {currentCallState === CallState.IN_CALL && (
            <Box sx={{color: 'text.primary', ...nameStyle}}>
              {otherParticipanteName}
            </Box>
          )}
        </Box>
      </Box>
    </>
  )
}

export default InCall
