import {useContext} from 'react'
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
  HashRouter,
} from 'react-router-dom'

import {ThemeProvider} from '@mui/material'
import Layout from 'app/components/Layout/Layout'
import SnackbarAlert from 'app/components/SnackbarAlert/SnackbarAlert'
import {
  Dial,
  Call,
  Contacts,
  History,
  SignMail,
  Settings,
  NoRoute,
  Login,
  ContactProfile,
  AddEditContact,
} from 'app/pages'
import {CallProvider} from 'app/utils/contexts/call.context'
import {ThemeMode} from 'app/utils/contexts/theme-mode.context'

const Router = window.electron ? HashRouter : BrowserRouter

const AppRouter = () => {
  const {theme} = useContext(ThemeMode)
  return (
    <ThemeProvider theme={theme}>
      <SnackbarAlert />
      <CallProvider>
        <Router>
          <Routes>
            <Route path='/' element={<Layout />}>
              <Route index element={<Dial />} />
              <Route path='/contacts' element={<Outlet />}>
                <Route path=':id' element={<Outlet />}>
                  <Route index element={<ContactProfile />} />
                  <Route path='edit' element={<AddEditContact />} />
                </Route>
                <Route path='add' element={<AddEditContact />} />
                <Route index element={<Contacts />} />
              </Route>
              <Route path='/history' element={<History />} />
              <Route path='/signmail' element={<SignMail />} />
              <Route path='/settings' element={<Settings />} />
              <Route path='/call' element={<Call />} />
              <Route path='*' element={<NoRoute />} />
            </Route>
            <Route path='/login' element={<Login />} />
          </Routes>
        </Router>
      </CallProvider>
    </ThemeProvider>
  )
}

export default AppRouter
