import {FC, PropsWithChildren, useContext, useEffect} from 'react'

import {Box, Icon, Button, Typography} from '@mui/material'
import {AuthContext} from 'app/utils/contexts/auth-context'
import {CallContext} from 'app/utils/contexts/call.context'
import {BRAND_COLOR} from 'app/utils/styles/colors'
import DotLoader from 'react-spinners/DotLoader'

import CallButton from './CallButton'

import {
  buttonsContainer,
  videoContainer,
  endCallButton,
  userVideoStyle,
  absoluteContainer,
  previewVideoGradient,
  container,
  nameStyle,
} from '../style'

const OutboundCall: FC<PropsWithChildren> = () => {
  const {
    myVideo,
    handleMyVideo,
    toggleVideo,
    toggleAudio,
    isVideoOff,
    isAudioMuted,
    leaveCall,
  } = useContext(CallContext)

  const {name} = useContext(AuthContext)

  useEffect(() => {
    handleMyVideo()
  }, [myVideo.current])

  return (
    <>
      <Box sx={videoContainer}>
        <Box
          sx={{
            display: 'gird',
            width: '100%',
            textAlign: 'center',
            position: 'absolute',
            zIndex: '1001',
            paddingTop: '145px',
          }}
        >
          <Box>
            <Typography sx={{color: 'text.primary', fontSize: '18px'}}>
              Calling...
            </Typography>
            <Typography sx={{color: 'text.primary', fontSize: '32px'}}>
              Maggie Rivers
            </Typography>
            <Typography
              sx={{color: 'text.primary', fontSize: '18px', paddingTop: '6px'}}
            >
              1 (801) 937-4623
            </Typography>
          </Box>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
              paddingTop: '240px',
            }}
          >
            <DotLoader size={174} loading color={BRAND_COLOR} />
          </Box>
        </Box>
        <Box sx={container}>
          <video
            playsInline
            ref={myVideo}
            autoPlay
            style={userVideoStyle}
            muted
          />
        </Box>
      </Box>
      <Box sx={previewVideoGradient} />
      <Box sx={{...absoluteContainer, bottom: '80px'}}>
        <Box sx={buttonsContainer}>
          <CallButton onClick={toggleVideo}>
            <Icon fontSize='large'>
              {isVideoOff ? 'videocam_off' : 'videocam'}
            </Icon>
          </CallButton>
          <CallButton onClick={toggleAudio}>
            <Icon fontSize='large'>{isAudioMuted ? 'mic_off' : 'mic'}</Icon>
          </CallButton>
          <Button
            onClick={leaveCall}
            sx={{bgcolor: 'error.main', ...endCallButton}}
          >
            <Icon fontSize='large'>call_end</Icon>
          </Button>
        </Box>
        <Box sx={{color: 'text.primary', ...nameStyle}}>
          {name || 'Sharlyn Clark'}
        </Box>
      </Box>
    </>
  )
}

export default OutboundCall
