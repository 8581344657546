import {
  FC,
  useState,
  useContext,
  useEffect,
  PropsWithChildren,
  MouseEvent,
} from 'react'
import {useNavigate} from 'react-router-dom'

import {Button, ToggleButtonGroup, ToggleButton, Icon} from '@mui/material'
import Box from '@mui/material/Box'
import ContentHeader from 'app/components/Content/ContentHeader'
import GroupedScrollableList from 'app/components/GroupedScrollableList/GroupedScrollableList'
import Page from 'app/components/Page/Page'
import {ContactsContext} from 'app/utils/contexts/contacts-context'

const Contacts: FC<PropsWithChildren> = () => {
  const [searchValue, setSearchValue] = useState('')
  const {contacts} = useContext(ContactsContext)
  const [filteredContacts, setFilteredContacts] = useState(contacts)
  const [contactFilter, setContactFilter] = useState('')

  const navigate = useNavigate()

  const handleSearch = (value: string) => {
    setSearchValue(value)
  }

  const handleFilter = (_event: MouseEvent<HTMLElement>, filter: string) => {
    if (filter !== null) {
      setContactFilter(filter)
    }
  }

  const handleContactFilter = () => {
    setFilteredContacts(
      contacts.filter(
        ({category}) =>
          contactFilter === '' ||
          (category && category.includes(contactFilter)),
      ),
    )
  }

  const handleAddContact = () => {
    navigate('add')
  }

  useEffect(() => {
    handleContactFilter()
  }, [contacts, contactFilter])

  return (
    <Page title='Contacts' id='contacts'>
      <Box
        sx={{
          display: 'grid',
          gridTemplateRows: `160px  5fr`,
          overflow: 'hidden',
        }}
      >
        <ContentHeader
          title='Contacts'
          searchBar
          searchBarPlaceholder='Search Contacts'
          searchCallBack={handleSearch}
        >
          <Button
            sx={{
              fontSize: '18px',
              width: '128px',
              textTransform: 'unset !important',
            }}
            onClick={handleAddContact}
          >
            <Icon sx={{paddingRight: '6px'}}>add</Icon> Add New
          </Button>
        </ContentHeader>
        <Box
          sx={{display: 'grid', gridTemplateRows: '60px', overflow: 'hidden'}}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              padding: '0 24px',
            }}
          >
            <ToggleButtonGroup
              size='small'
              value={contactFilter}
              exclusive
              onChange={handleFilter}
              sx={{
                bgcolor: 'secondary.main',
                width: '100%',
                height: '40px',
                borderRadius: '6px',
                '& .MuiToggleButtonGroup-grouped': {
                  margin: '3px',
                  border: 0,
                  '&:not(:last-of-type)': {
                    width: '100%',
                    borderRadius: '6px',
                    color: 'text.primary',
                    fontSize: '16px',
                    textTransform: 'unset !important',
                  },
                  '&:last-of-type': {
                    width: '100%',
                    borderRadius: '6px',
                    color: 'text.primary',
                    fontSize: '16px',
                    textTransform: 'unset !important',
                  },
                },
              }}
            >
              <ToggleButton
                value=''
                sx={{
                  '&.Mui-selected': {
                    bgcolor: 'primary.main',
                  },
                }}
              >
                All Contacts
              </ToggleButton>
              <ToggleButton
                value='favorites'
                sx={{
                  '&.Mui-selected': {
                    bgcolor: 'primary.main',
                  },
                }}
              >
                Favorites
              </ToggleButton>
              <ToggleButton
                value='sorenson'
                sx={{
                  '&.Mui-selected': {
                    bgcolor: 'primary.main',
                  },
                }}
              >
                Sorenson
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
          <GroupedScrollableList
            items={filteredContacts}
            searchValue={searchValue}
          />
        </Box>
      </Box>
    </Page>
  )
}
export default Contacts
