import Router from 'app/components/Router/Router'
import {AlertContextProvider} from 'app/utils/contexts/alert-context'
import {AuthContextProvider} from 'app/utils/contexts/auth-context'
import {ContactsContextProvider} from 'app/utils/contexts/contacts-context'
import {ThemeModeProvider} from 'app/utils/contexts/theme-mode.context'

const App = () => (
  <AuthContextProvider>
    <ThemeModeProvider>
      <AlertContextProvider>
        <ContactsContextProvider>
          <Router />
        </ContactsContextProvider>
      </AlertContextProvider>
    </ThemeModeProvider>
  </AuthContextProvider>
)

export default App
