import {FC} from 'react'

import {Box} from '@mui/material'

import {secondsToMinutes} from '../../utils/functions/timeConverter'

interface IThumbnail {
  url: string
  playBackTime: number
}

const Thumbnail: FC<IThumbnail> = ({url, playBackTime}) => (
  <Box
    style={{
      width: '86px',
      height: '64px',
      borderRadius: '6px',
      backgroundImage: `url(${url})`,
      backgroundSize: '80px 60px',
      display: 'flex',
      alignItems: 'end',
      justifyContent: 'left',
    }}
  >
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '2px 6px',
        bgcolor: 'background.paper',
        opacity: '0.9',
        borderRadius: '5px',
        color: 'text.primary',
        fontSize: '12px',
        margin: '6px',
      }}
    >
      {secondsToMinutes(playBackTime!)}
    </Box>
  </Box>
)

export default Thumbnail
