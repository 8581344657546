import {FC, PropsWithChildren, useState} from 'react'

import {Typography, Box} from '@mui/material'
import ContentHeader from 'app/components/Content/ContentHeader'
import ScrollableList from 'app/components/ScrollableList/ScrollableList'
import VideoPlayer from 'app/components/VideoPlayer/VideoPlayer'

interface ISignMail {
  name: string
  thumbNailUrl: string
  videoUrl: string
  playBackTime: number
}

const SignMail: FC<PropsWithChildren> = () => {
  const items = [
    {
      name: 'Abigail Smith',
      phoneNumber: '18001298567',
      date: new Date('2021/01/01'),
      thumbNailUrl:
        'https://thumbs.dreamstime.com/b/no-image-available-icon-flat-vector-no-image-available-icon-flat-vector-illustration-132482953.jpg',
      videoUrl: 'https://gdurl.com/S71k',
      playBackTime: 23,
    },
    {
      name: 'Bryan Robbins',
      phoneNumber: '18008614562',
      date: new Date('2021/01/01'),
      thumbNailUrl:
        'https://thumbs.dreamstime.com/b/no-image-available-icon-flat-vector-no-image-available-icon-flat-vector-illustration-132482953.jpg',
      videoUrl: 'https://gdurl.com/gzkx',
      playBackTime: 12,
    },
    {
      name: 'Will Austin',
      phoneNumber: '180012746671',
      date: new Date('2021/05/12'),
      thumbNailUrl:
        'https://thumbs.dreamstime.com/b/no-image-available-icon-flat-vector-no-image-available-icon-flat-vector-illustration-132482953.jpg',
      videoUrl: 'https://gdurl.com/S71k',
      playBackTime: 23,
    },
    {
      name: 'Sharlyn Clark',
      phoneNumber: '18001238728',
      date: new Date('2021/07/03'),
      thumbNailUrl:
        'https://thumbs.dreamstime.com/b/no-image-available-icon-flat-vector-no-image-available-icon-flat-vector-illustration-132482953.jpg',
      videoUrl: 'https://gdurl.com/S71k',
      playBackTime: 23,
    },
    {
      name: 'John Williams',
      phoneNumber: '18001271167',
      date: new Date('2021/09/17'),
      thumbNailUrl:
        'https://thumbs.dreamstime.com/b/no-image-available-icon-flat-vector-no-image-available-icon-flat-vector-illustration-132482953.jpg',
      videoUrl: 'https://gdurl.com/S71k',
      playBackTime: 23,
    },
    {
      name: 'Bob Johnson',
      phoneNumber: '18007489666',
      date: new Date('2021/11/19'),
      thumbNailUrl:
        'https://thumbs.dreamstime.com/b/no-image-available-icon-flat-vector-no-image-available-icon-flat-vector-illustration-132482953.jpg',
      videoUrl: 'https://gdurl.com/S71k',
      playBackTime: 23,
    },
    {
      name: 'April Sanders',
      phoneNumber: '18009731567',
      date: new Date('2021/12/01'),
      thumbNailUrl:
        'https://thumbs.dreamstime.com/b/no-image-available-icon-flat-vector-no-image-available-icon-flat-vector-illustration-132482953.jpg',
      videoUrl: 'https://gdurl.com/S71k',
      playBackTime: 23,
    },
    {
      name: 'Eric Robbins',
      phoneNumber: '18008794564',
      date: new Date('2022/01/01'),
      thumbNailUrl:
        'https://thumbs.dreamstime.com/b/no-image-available-icon-flat-vector-no-image-available-icon-flat-vector-illustration-132482953.jpg',
      videoUrl: 'https://gdurl.com/S71k',
      playBackTime: 23,
    },
    {
      name: 'Ed Austin',
      phoneNumber: '18006845563',
      date: new Date('2022/01/07'),
      thumbNailUrl:
        'https://thumbs.dreamstime.com/b/no-image-available-icon-flat-vector-no-image-available-icon-flat-vector-illustration-132482953.jpg',
      videoUrl: 'https://gdurl.com/S71k',
      playBackTime: 23,
    },
    {
      name: 'Sharlyn Clark',
      phoneNumber: '18001282455',
      date: new Date('2022/01/09'),
      thumbNailUrl:
        'https://thumbs.dreamstime.com/b/no-image-available-icon-flat-vector-no-image-available-icon-flat-vector-illustration-132482953.jpg',
      videoUrl: 'https://gdurl.com/S71k',
      playBackTime: 23,
    },
    {
      name: 'John Williams',
      phoneNumber: '18001234864',
      date: new Date('2022/01/17'),
      thumbNailUrl:
        'https://thumbs.dreamstime.com/b/no-image-available-icon-flat-vector-no-image-available-icon-flat-vector-illustration-132482953.jpg',
      videoUrl: 'https://gdurl.com/S71k',
      playBackTime: 23,
    },
    {
      name: 'Amanda Rivers',
      phoneNumber: '18001232157',
      date: new Date('2022/01/19'),
      thumbNailUrl:
        'https://thumbs.dreamstime.com/b/no-image-available-icon-flat-vector-no-image-available-icon-flat-vector-illustration-132482953.jpg',
      videoUrl: 'https://gdurl.com/S71k',
      playBackTime: 23,
    },
    {
      name: 'Isaac Johnson',
      phoneNumber: '18001887567',
      date: new Date('2022/01/20'),
      thumbNailUrl:
        'https://thumbs.dreamstime.com/b/no-image-available-icon-flat-vector-no-image-available-icon-flat-vector-illustration-132482953.jpg',
      videoUrl: 'https://gdurl.com/S71k',
      playBackTime: 23,
    },
  ]

  const [searchValue, setSearchValue] = useState('')
  const [selectedSignMail, setSelectedSignMail] = useState<
    ISignMail | undefined
  >()
  const handleSearch = (value: string) => {
    setSearchValue(value)
  }

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: '2fr 3fr',
        height: '100%',
        width: '100%',
      }}
    >
      <Box
        sx={{
          display: 'grid',
          gridTemplateRows: `160px  5fr`,
          overflow: 'hidden',
        }}
      >
        <ContentHeader
          title='SignMail'
          searchBar
          searchBarPlaceholder='Search SignMail'
          searchCallBack={handleSearch}
        />
        <ScrollableList
          items={items}
          searchValue={searchValue}
          onSelect={setSelectedSignMail}
        />
      </Box>
      {selectedSignMail ? (
        <Box
          sx={{
            backgroundColor: 'primary.light',
            padding: '98px',
          }}
        >
          <Box sx={{display: 'flex'}}>
            <Typography
              sx={{
                fontSize: '32px',
                fontWeight: 'bold',
                color: 'text.disabled',
                paddingBottom: '60px',
              }}
            >
              SignMail /
            </Typography>
            <Typography
              sx={{
                fontSize: '32px',
                fontWeight: 'bold',
                color: 'secondary.main',
                paddingLeft: '6px',
              }}
            >
              {selectedSignMail.name}
            </Typography>
          </Box>
          <VideoPlayer
            playBackTime={selectedSignMail.playBackTime}
            videoUrl={selectedSignMail.videoUrl}
          />
        </Box>
      ) : (
        <Box
          sx={{
            backgroundColor: 'primary.light',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              width: '480px',
              height: '80px',
              bgcolor: 'text.primary',
              borderRadius: '90px',
              display: 'grid',
              gridTemplateRows: '42px 26px',
              justifyContent: 'center',
              alignItems: 'center',
              color: 'secondary.main',
            }}
          >
            <Typography sx={{fontSize: '22px', fontWeight: 'bold'}}>
              SignMail
            </Typography>
            <Typography sx={{fontSize: '16px'}}>
              Select one of the signmails on the left to play
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  )
}
export default SignMail
