import {ReactComponent as ContactsSVG} from './contacts.svg'
import {ReactComponent as DialSVG} from './dial.svg'
import {ReactComponent as HistorySVG} from './history.svg'
import {ReactComponent as LogoSVG} from './logo.svg'
import {ReactComponent as SettingsSVG} from './settings.svg'
import {ReactComponent as SignMailSVG} from './signmail.svg'
import {ReactComponent as UnderConstruction} from './under-construction.svg'

export const ICON_MAP = {
  contacts: ContactsSVG,
  dial: DialSVG,
  settings: SettingsSVG,
  signmail: SignMailSVG,
  history: HistorySVG,
  logo: LogoSVG,
  underConstruction: UnderConstruction,
}

export type IconType = keyof typeof ICON_MAP
